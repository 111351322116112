import React from "react";
import {useTranslation} from "react-i18next";
import {inject, observer} from "mobx-react";
// local
import PresentationCardWrapper from "../../PresentationCardWrapper";
import {dateFormat} from "helpers/datetime";
import MemberOfEdit from "./MemberOfEdit";
import OverviewCardView from "../../OverviewCard/OverviewCardView";
import EmptyData from "components/EmptyData";
import {IconPaperPencil} from "components/icons";
import {LockValue} from "components/LockValue";

const MemberOf = inject("store")(
  observer(({store: {organization}, edit, setEdit}) => {
    const {t} = useTranslation();
    const {organizationDetail} = organization;
    const closeEdit = () => setEdit({...edit, members: false});

    const overviewMemberColumnTitles = [
      "OVERVIEW_TAB_COLUMN_ORGANISATION",
      "OVERVIEW_TAB_COLUMN_MEMBER_SINCE",
      "OVERVIEW_TAB_COLUMN_SOURCE",
    ];

    if (edit.members) return <MemberOfEdit closeEdit={closeEdit} />;

    const organizationMembers = organizationDetail?.Members;
    const companyMemberships = organizationDetail?.Company?.memberships
      ? organizationDetail?.Company?.memberships.map((member) => ({
          organization: member.name,
          since: member.admissionDate,
          source: member.name,
          isIndustryMember: true,
        }))
      : null;

    const mapManualData = () =>
      organizationMembers?.map((item) => (
        <OverviewCardView
          key={item.id}
          columnTitles={overviewMemberColumnTitles}
          columnData={{
            0: t(item.organization),
            1: dateFormat(item.since),
            2: t("COMPANY"),
          }}
          wideColumnIndex={0}
        />
      ));

    return (
      <PresentationCardWrapper
        icon={<IconPaperPencil />}
        title={t("MEMBER_OF")}
        edit={edit}
        setEdit={setEdit}
        editName="members"
        addClass="__member-of-area"
        isActive={organizationDetail?.isActive}
      >
        <LockValue
          canPermissions={["READ_OTHER_MEMBERS"]}
          noHideOurInfo
          fullSubcribeButton
          needMiniSibscribeButton
        >
          <OverviewCardView
            columnTitles={overviewMemberColumnTitles}
            isTitle
            wideColumnIndex={0}
          />

          {companyMemberships?.map((companyMember, index) => (
            <OverviewCardView
              key={index}
              isSourceShield={companyMember.isIndustryMember}
              columnTitles={overviewMemberColumnTitles}
              columnData={{
                0: companyMember.organization,
                1: dateFormat(companyMember.since),
                2: companyMember.source,
              }}
              wideColumnIndex={0}
            />
          ))}
          {mapManualData()}
          {!companyMemberships && !organizationMembers?.length && <EmptyData />}
        </LockValue>
      </PresentationCardWrapper>
    );
  })
);

export default MemberOf;
