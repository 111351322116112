import React from "react";
//local
import {Upload} from "antd";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {IconEdit} from "components/icons";

const PresentationPageLogo = ({logotype, isActive, openEdit}) => {
  return (
    <div className="presentation-page-header__logo">
      <Upload
        name="file"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        disabled={true}
      >
        {logotype?.Location ? (
          <span>
            <img
              src={logotype.Location}
              alt="Logo"
              style={{width: "90%", height: "auto"}}
            />
          </span>
        ) : (
          <span>No company logo</span>
        )}
      </Upload>
      {isActive && (
        <ButtonTransparent
          icon={<IconEdit />}
          noBorder
          addClass="pa-0"
          onClick={openEdit}
        />
      )}
    </div>
  );
};

export default PresentationPageLogo;
