import React from "react";
import {useTranslation} from "react-i18next";
//local
import {Modal} from "components/Modal";
import {IconCalendar} from "components/icons";
import {LockValue} from "components/LockValue";

export const SubscriptionUpgrageModal = ({
  canPermissions,
  subscriptionName,
  subscriptionAction,
  subscriptionDescription,
  onClose,
}) => {
  const {t} = useTranslation();

  return (
    <Modal
      onClose={onClose}
      width={600}
      boldTitle
      noCenterContent
      titleIcon={<IconCalendar addClass="mr-4" />}
      titleText={`${t(
        "SUBSCRIPTION_UPGRADE_MODAL_TITLE_01"
      )} ${subscriptionName} ${subscriptionAction}`}
      addClass="subscription-upgrade-modal__wrapper"
    >
      <div className="pa-8">
        <LockValue
          canPermissions={canPermissions}
          fullSubcribeButton
          fullSubscribeVerticalPosition
          addClassDescription="fz-16 mb-6"
          fullSubcribeButtonClassName="subscription-upgrade-modal__locked"
        />
      </div>
    </Modal>
  );
};
