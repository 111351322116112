import React from "react";
import {useNavigate} from "react-router-dom";
//local
import {CustomBadge} from "components/CustomBadge";
import {TextBadge} from "components/TextBadge";
import {toRequestToUsId} from "constants/routes.const";
import {dateTimeFormat} from "helpers/datetime";
import {IconMapMarker, IconChat} from "components/icons";
import {TextCard} from "components/TextCard";
import {color} from "constants/color.consts";

const OurOfferTableItem = ({offer}) => {
  const navigate = useNavigate();

  const handleRedirectOnClick = () => {
    const path = toRequestToUsId(offer.ProposalId);
    navigate(path, {state: {from: "offers", offerId: offer.id}});
  };

  return (
    <div className="requests-card_body" onClick={handleRedirectOnClick}>
      <div className="requests-card_body__name __wrapper">
        <TextCard text={offer.Proposal.Request.Buyer.name} />
      </div>
      <div className="requests-card_body__column __wrapper df">
        <IconMapMarker addClass="mr-1 mt-1" />
        <div>{offer.Proposal.Request.location}</div>
      </div>
      <div className="requests-card_body__column __wrapper title">
        {offer.Proposal.Request.name}
      </div>
      <div className="requests-card_body__column __wrapper date">
        {dateTimeFormat(offer.Proposal.Request.closedAt)}
      </div>
      <div className="requests-card_body__column __wrapper date">
        {offer.evaluate ? (
          <TextBadge text={"EVALUATING"} color="green" />
        ) : (
          offer.evaluate !== null && <TextBadge text={"DECLINED"} color="red" />
        )}
      </div>
      <div className="requests-card_body__indicator __wrapper">
        <CustomBadge
          count={offer?.Proposal.Request?.messages?.length || 0}
          addClass={`mr-2`}
        />
        <IconChat addClass={`icon-table`} color={color.black} />
      </div>
    </div>
  );
};

export default OurOfferTableItem;
