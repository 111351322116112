import React, {useState, useEffect} from "react";
import {useLocation} from "react-router-dom";
//local
import {settingsTabs} from "constants/tabs.const";
import {Tabs} from "components/Tabs";
import {inject, observer} from "mobx-react";
import {hasUserPermission} from "helpers/helper";
import {roles} from "constants/auth.const";

export const SettingsTabs = inject("store")(
  observer(({store: {auth, organization}, ifDrawer}) => {
    const params = useLocation();
    const {subscriptions} = organization;
    const [active, setActive] = useState(
      settingsTabs.find((item) => item.hash === params?.hash)?.id ?? 0
    );

    useEffect(() => {
      settingsTabs.forEach((item) => {
        if (item.hash === params.hash) {
          setActive(item.id);
        }
      });
    }, [params.hash]);

    useEffect(() => {
      (ifDrawer === true || (ifDrawer && !subscriptions?.length)) &&
        organization.getAvailableSubscriptions();
    }, [ifDrawer, organization, subscriptions?.length]);

    return (
      <div className="modal-settings__content">
        <Tabs
          tabs={settingsTabs.filter(
            (item) =>
              !(
                item.title === "INVITE_USERS" &&
                !hasUserPermission([roles.ADMIN, roles.OWNER], auth?.clientRole)
              )
          )}
          active={active}
          setActive={setActive}
          addClass="modal-settings__tabs"
        />
      </div>
    );
  })
);
