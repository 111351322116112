import React, {useMemo, useCallback} from "react";
import {useTranslation} from "react-i18next";
// local
import {subscriptionRoles} from "constants/auth.const";

export const MembersSummary = ({members}) => {
  const {t} = useTranslation();

  const totalMembersCount = useMemo(() => members.length, [members]);

  const membersRoleCount = useCallback(
    (role) => members.filter((item) => item.SubscribeRoleId === role).length ?? 0,
    [members]
  );

  const RoleCount = ({role}) => [
    <span>{`${t(role)}:`}</span>,
    <span className="fz-12 mr-4 ml-1">{membersRoleCount(role)}</span>,
  ];

  return (
    <span className="members__summary">
      <span className="black">{`${t("USER_ROLE_MEMBERS_SUMMARY_TOTAL")}:`}</span>
      <span className="black fz-12 mr-4 ml-1">{totalMembersCount}</span>
      {Object.values(subscriptionRoles).map((role) => (
        <RoleCount key={role} role={role} />
      ))}
    </span>
  );
};
