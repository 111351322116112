import React, {useState, useRef} from "react";
import {useTranslation} from "react-i18next";
import {Formik, Form} from "formik";
import {Link} from "react-router-dom";
// local
import {ContractContactInfo} from "./ContractContactInfo";
import {LastSignatureModal} from "./LastSignatureModal";
import EditSelect from "components/selects/EditSelect";
import {
  IconHourglass,
  IconCheck,
  IconPhone,
  IconPlane,
  IconPlusEmpty,
} from "components/icons";
import {color} from "constants/color.consts";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {IconBankIdLogo} from "components/icons";
import ButtonDelete from "components/buttons/ButtonDelete";
import {InitialsBadge} from "components/InitialsBadge";
import {getFullName} from "helpers/helper";
import {dateTimeFormat} from "helpers/datetime";
import cn from "classnames";

export const ContractSignatories = ({
  contractOwnPersons,
  contractOtherPersons,
  isFinishedOwnSignatures,
  isFinishedOtherSignatures,
  ownOrganizationName,
  otherOrganizationName,
  membersList,
  isSellerSide,
  currentUser,
  canDeleteMember,
  onSendMessage,
  onSubmit,
  onAddSignatory,
  onDeleteSignatory,
}) => {
  const {t} = useTranslation();
  const formRef = useRef();
  const canAddMembers = !isFinishedOwnSignatures && !!membersList.length;
  const hasMembersExceptLoggedInOwnPersons = !!contractOwnPersons?.filter(
    (person) => person.UserId !== currentUser.id
  ).length;
  const [showLastSignatureModal, setShowLastSignatureModal] = useState(null);
  const ownSign = isSellerSide ? "SellerSign" : "BuyerSign";
  const ownSignId = isSellerSide ? "SellerSignId" : "BuyerSignId";
  const otherSign = isSellerSide ? "BuyerSign" : "SellerSign";

  const checkLastSignature = (action, payload) => {
    if (action === "sign") {
      contractOwnPersons
        .filter((person) => person.UserId !== payload.UserId)
        .every((user) => !!user[ownSignId])
        ? setShowLastSignatureModal({action, payload})
        : onSubmit(payload);
    }
    if (action === "delete") {
      contractOwnPersons
        .filter((person) => person.UserId !== payload)
        .every((user) => !!user[ownSignId])
        ? setShowLastSignatureModal({action, payload})
        : onDeleteSignatory(payload);
    }
  };

  return (
    <div className="contract-tab_signatures pt-8">
      <div className="contract-tab_signatures__title __uppercase">{t("SIGNATURES")}</div>
      <div
        className={cn("contract-tab_signatures__sides pt-8", {
          __reverse: isSellerSide,
        })}
      >
        <div className="contract-tab_signatures__own-side">
          <div className="contract-tab_signatures__title mb-8">
            {t("SIGN_FOR")} {ownOrganizationName}
            {isFinishedOwnSignatures ? (
              <IconCheck color={color.green} />
            ) : (
              <IconHourglass />
            )}
          </div>

          {canAddMembers && (
            <h4 className="contract-tab_signatures__add-signatory-title">
              {t("CONTRACT_OWN_SIDE_ADD_SIGNATORY_TITLE")}
            </h4>
          )}
          {canAddMembers && (
            <Formik
              enableReinitialize
              innerRef={formRef}
              initialValues={{ContactPersonId: null}}
              onSubmit={onAddSignatory}
            >
              {({resetForm, submitForm}) => (
                <Form className="contract-tab_signatures__add-signatory-select">
                  <EditSelect
                    name="ContactPersonId"
                    list={membersList}
                    ownValue={currentUser.id}
                    placeholderColorBlue
                    placeholder="CONTRACT_OWN_SIDE_ADD_SIGNATORY_PLACEHOLDER"
                    placeholderPrefixIcon={<IconPlusEmpty className />}
                    onChange={() => submitForm().then(resetForm)}
                    small
                    initials
                  />
                </Form>
              )}
            </Formik>
          )}

          {contractOwnPersons?.map((person) => (
            <div
              key={person.id}
              className={cn("contract-tab_signatures__signatory", {
                "__border-bottom": person.UserId !== currentUser.id,
                "__first-in-list": person.UserId === currentUser.id,
              })}
            >
              <div className="full-width">
                <div className="df-row-center">
                  <InitialsBadge
                    firstName={person.User?.firstName}
                    lastName={person.User?.lastName}
                    large
                    isCurrentUser={person.UserId === currentUser.id}
                    addClass="mr-4 message-panel"
                  />
                  <div>
                    <span className="fz-16">
                      {getFullName(person.User?.firstName, person.User?.lastName)}
                    </span>
                    <div className="df-row-center">
                      <IconPhone height="15" width="15" />
                      <span className="ml-2">{person.User?.mobilePhone}</span>
                    </div>
                  </div>
                </div>

                {!person[ownSignId] && person.UserId === currentUser.id && (
                  <ButtonTransparent
                    name={"SIGN_NOW_BUTTON"}
                    iconRight={<IconBankIdLogo color="#fff" />}
                    onClick={() => checkLastSignature("sign", {UserId: person.UserId})}
                    addClass="mt-5 mb-1"
                    nonTransparent
                  />
                )}
              </div>
              <div className="df-row-center">
                {person.UserId !== currentUser.id && !person[ownSignId] && (
                  <div className="df-row-center">
                    <IconPlane addClass="mr-2" />
                    <span className="contract-tab_signatures__status-label">
                      {t("NOTIFIED")}
                    </span>
                  </div>
                )}
                {person[ownSignId] && (
                  <div className="df-row-center">
                    <IconCheck addClass="mr-2" color={color.green} />
                    <span className="contract-tab_signatures__status-label green">
                      {t("SIGNED")}
                    </span>
                    <span className="contract-tab_signatures__status-data">
                      {dateTimeFormat(person[ownSign]?.createdAt, "-")}
                    </span>
                  </div>
                )}
                {canDeleteMember && (
                  <ButtonDelete
                    addClass="pr-0 ml-4"
                    onClick={() => checkLastSignature("delete", person.UserId)}
                    activeOnHover
                  />
                )}
              </div>
            </div>
          ))}

          <div className={`${hasMembersExceptLoggedInOwnPersons ? "mt-8" : ""}`}>
            {t("CONTRACT_OWN_SIDE_ADD_SIGNATORY_DESCRIPTION")}
          </div>
          <Link to="#members">{t("CONTRACT_OWN_SIDE_ADD_SIGNATORY_LINK")}</Link>
        </div>
        <div className="contract-tab_signatures__other-side">
          <div className="contract-tab_signatures__title mb-8">
            {t("SIGN_FOR")} {otherOrganizationName}
            {isFinishedOtherSignatures && <IconCheck color={color.green} />}
          </div>
          {isFinishedOtherSignatures ? (
            contractOtherPersons.map((person) => (
              <ContractContactInfo key={person.id} person={person[otherSign]} />
            ))
          ) : (
            <div>
              <div className="contract-tab_signatures__waiting-text mb-8">
                <IconHourglass />
                {t("WAITING_SIGNATURE")}
              </div>

              {!isSellerSide && (
                <div className="mb-4">
                  {`${otherOrganizationName} ${t(
                    "CONTRACT_SELLER_SIDE_WAITING_DESCRIPTION_01"
                  )}`}
                </div>
              )}
              {!isSellerSide && (
                <div className="mb-4">
                  {t("CONTRACT_SELLER_SIDE_WAITING_DESCRIPTION_02")}
                </div>
              )}
              <Link to="#" onClick={onSendMessage}>
                {`${t("CONTRACT_SELLER_SIDE_WAITING_LINK")} ${otherOrganizationName}.`}
              </Link>
            </div>
          )}
        </div>
      </div>
      {!!showLastSignatureModal && (
        <LastSignatureModal
          organizationName={ownOrganizationName}
          onOk={() => {
            showLastSignatureModal.action === "sign" &&
              onSubmit(showLastSignatureModal.payload);
            showLastSignatureModal.action === "delete" &&
              onDeleteSignatory(showLastSignatureModal.payload);
          }}
          onClose={() => setShowLastSignatureModal(null)}
        />
      )}
    </div>
  );
};
