import React from "react";
import {useTranslation} from "react-i18next";
//local
import {dateFormat} from "helpers/datetime";
import {showSuccess} from "helpers/notifications.helper";
import ButtonDelete from "components/buttons/ButtonDelete";
import {ConfirmModal} from "components/ConfirmModal";

const InviteUserList = ({organizationStore}) => {
  const {t} = useTranslation();

  const deleteInviteToUser = (invitationId) => {
    organizationStore
      .deleteInviteUsers(invitationId)
      .then(() => showSuccess(t("DELETING_INVITE_SUCCESSFULLY_COMPLETED")));
  };

  const confirmDelete = (id) => {
    ConfirmModal({
      title: t("WANT_DELETE_INVITATION"),
      text: t("CANT_RETURN"),
      type: "warning",
      onOk: () => deleteInviteToUser(id),
    });
  };

  return organizationStore?.inviteUsers?.map((item) => (
    <div key={item.id} className="invite-user__table __item">
      <div className="invite-user__table __value __to">{item.email}</div>
      <div className="invite-user__table __value __sent">
        <span>{t("INVITE_SENT_ON")}</span>
        {dateFormat(item.updatedAt)}
      </div>
      <div className="invite-user__table __actions">
        <ButtonDelete onClick={() => confirmDelete(item.id)} />
      </div>
    </div>
  ));
};

export default InviteUserList;
