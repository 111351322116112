import React from "react";
//local
import {
  IconLinkOneDrive,
  IconLinkGoogleDrive,
  IconLinkDropbox,
  IconLinkByggnet,
  IconLinkBluebeam,
  IconLinkDox,
  IconLinkTreano,
  IconLinkBotrygg,
  IconLinkGbj,
  IconLinkOljibe,
  IconLinkMarkEnergiByggarna,
  IconLinkShbygg,
  IconLinkSveab,
  IconLinkByggmastargruppen,
  IconLinkRocmore,
  IconLinkDalux,
  IconLinkYitsharepoint,
  IconLinkWebforum,
  IconLinkInteroc,
  IconLinkIbinder,
  IconLinkSoderbyEntreprenad,
  IconDcWast,
  IconDocumentDalux,
} from "components/icons";
import {color} from "./color.consts";

export const emptySmartFormQuestion = {
  label: "",
  type: "boolean",
  required: true,
  min: null,
  max: null,
  maxlength: 255,
};

export const emptySmartFormHeadingQuestion = {
  label: "",
  type: "heading",
  required: false,
  min: null,
  max: null,
  maxlength: 255,
};

export const emptyRequest = {
  name: "",
  description: "",
  location: null,
  offersHidden: false,
  hardDateCloseOffers: false,
  reminders: true,
  reminderDays: [1, 3, 7, 14, 30],
  ContactPersonId: null,
  ProjectId: null,
  closedAt: null,
  standardAgreement: "",
  documents: [],
  recipients: {
    registered: [],
    notRegistered: [],
  },
  messages: [],
  smartFormQuestions: [],
  entrepreneurWithin: [],
  entrepreneurOffers: [],
  materialSupplierType: [],
  consultingServices: [],
};

export const emptyProject = {
  name: "",
  reference: "",
  DepartmentId: null,
  location: "",
  buyer: "",
  Members: [],
  availableIn: [],
  isPrivate: false,
};

export const emptyProcurement = {
  name: "",
  UserId: "",
};

export const emptyManualOffer = {
  organizationName: "",
  price: null,
  validUntil: null,
  notes: "",
  documents: [],
};

export const adjustmentSymbolsAndColors = {
  less: {
    symbol: "",
    color: color.black,
  },
  equally: {
    symbol: "\u00B1",
    color: color.lightGray,
  },
  more: {
    symbol: "+",
    color: color.black,
  },
};

export const budgetSymbolsAndColors = {
  less: {
    symbol: "-",
    color: color.green,
  },
  equally: {
    symbol: "\u00B1",
    color: color.green,
  },
  more: {
    symbol: "+",
    color: color.red,
  },
};

export const newProjectInfo = {
  name: "",
  location: "",
  buyer: "",
  reference: "",
};

export const extLinkDomains = [
  "google.com",
  "google.se",
  "system2.byggnet.com",
  "projekt.treano.se",
  "botrygg.sharepoint.com",
  "gbjbyggab.sharepoint.com",
  "oljibe-my.sharepoint.com",
  "markenergibyggarna-my.sharepoint.com",
  "shbygg.sharepoint.com",
  "shbygg-my.sharepoint.com",
  "sveab-my.sharepoint.com",
  "build.dalux.com",
  "yitgroup-my.sharepoint.com",
  "webforum.com",
  "workspace.interoc.se",
  "interocab-my.sharepoint.com",
  "byggmastargrup787.sharepoint.com",
  "rocmoreab.onmicrosoft.com",
  "rocmoreab.sharepoint.com",
  "dcwast-my.sharepoint.com",
  "dropbox.com",
  "1drv.ms",
  "dox.se",
  "ibinder.com",
  "soderbyentreprenad-my.sharepoint.com",
  "soderbyentreprenad.sharepoint.com",
  "bluebeamstudio.se",
  "bluebeamstudio.de",
  "bluebeamstudio.co.uk",
  "studio.bluebeam.com",
  "daluxFile",
];

export const extLinkIcons = {
  "google.com": <IconLinkGoogleDrive />,
  "google.se": <IconLinkGoogleDrive />,
  "system2.byggnet.com": <IconLinkByggnet />,
  "projekt.treano.se": <IconLinkTreano />,
  "botrygg.sharepoint.com": <IconLinkBotrygg />,
  "gbjbyggab.sharepoint.com": <IconLinkGbj />,
  "oljibe-my.sharepoint.com": <IconLinkOljibe />,
  "markenergibyggarna-my.sharepoint.com": <IconLinkMarkEnergiByggarna />,
  "shbygg.sharepoint.com": <IconLinkShbygg />,
  "shbygg-my.sharepoint.com": <IconLinkShbygg />,
  "sveab-my.sharepoint.com": <IconLinkSveab />,
  "byggmastargrup787.sharepoint.com": <IconLinkByggmastargruppen />,
  "rocmoreab.onmicrosoft.com": <IconLinkRocmore />,
  "rocmoreab.sharepoint.com": <IconLinkRocmore />,
  "build.dalux.com": <IconLinkDalux />,
  "yitgroup-my.sharepoint.com": <IconLinkYitsharepoint />,
  "webforum.com": <IconLinkWebforum />,
  "workspace.interoc.se": <IconLinkInteroc />,
  "interocab-my.sharepoint.com": <IconLinkInteroc />,
  "dcwast-my.sharepoint.com": <IconDcWast />,
  "dropbox.com": <IconLinkDropbox />,
  "1drv.ms": <IconLinkOneDrive />,
  "dox.se": <IconLinkDox />,
  "ibinder.com": <IconLinkIbinder />,
  "soderbyentreprenad-my.sharepoint.com": <IconLinkSoderbyEntreprenad />,
  "soderbyentreprenad.sharepoint.com": <IconLinkSoderbyEntreprenad />,
  "bluebeamstudio.se": <IconLinkBluebeam />,
  "bluebeamstudio.de": <IconLinkBluebeam />,
  "bluebeamstudio.co.uk": <IconLinkBluebeam />,
  "studio.bluebeam.com": <IconLinkBluebeam />,

  daluxFile: <IconDocumentDalux />,
};

export const rfpStatuses = {
  DRAFT: "DRAFT",
  PUBLISHED: "PUBLISHED",
  REMOVED: "REMOVED",
  STOPPED: "STOPPED",
};

export const procurementStatuses = {
  SELECT_STATUS: "PROJECT_ITEM_STATUS_SELECT_STATUS",
  NO_STATUS: "PROJECT_ITEM_STATUS_NO_STATUS",
  RFP_DRAFT: "PROJECT_ITEM_STATUS_RFP_DRAFT",
  RFP_OUT: "PROJECT_ITEM_STATUS_RFP_OUT",
  OFFERS_IN: "PROJECT_ITEM_STATUS_OFFERS_IN",
  EVALUATING: "PROJECT_ITEM_STATUS_EVALUATING",
  CONTRACT_DRAFT: "PROJECT_ITEM_STATUS_CONTRACT_DRAFT",
  CONTRACT: "PROJECT_ITEM_STATUS_CONTRACT",
  STOPPED: "PROJECT_ITEM_STATUS_STOPPED",
};

export const noOffice = "no_office";
export const allOffices = "00000000-0000-0000-0000-000000000000";

export const noOfficeItem = {label: "SELECT_NO_DEPARTMENT", value: noOffice};
export const allOfficesItem = {label: "SELECT_ALL_DEPARTMENTS", value: allOffices};

export const sortProcurementsSelectItems = [
  {label: "PROCUREMENT_SORT_SELECT_NAME", value: "name"},
  {label: "PROCUREMENT_SORT_SELECT_DATE", value: "createdAt"},
  {label: "PROCUREMENT_SORT_SELECT_TIME", value: "closedAt"},
];

export const bulkProcurementOperations = {
  PROCUREMENT_BULK_OPERATION_MESSAGE_TO_ALL: "PROCUREMENT_RFT_MESSAGE_TO_ALL",
  PROCUREMENT_BULK_OPERATION_UPDATE_CLOSED_AT: "PROCUREMENT_RFT_UPDATE_CLOSED_AT",
};

export const medalRating = {
  MEDAL_GOLD: "MEDAL_GOLD",
  MEDAL_SILVER: "MEDAL_SILVER",
  MEDAL_BRONZE: "MEDAL_BRONZE",
};

export const offerMedalRating = {
  [medalRating.MEDAL_GOLD]: "OFFER_MEDAL_GOLD",
  [medalRating.MEDAL_SILVER]: "OFFER_MEDAL_SILVER",
  [medalRating.MEDAL_BRONZE]: "OFFER_MEDAL_BRONZE",
};

export const offerToMedalRating = {
  OFFER_MEDAL_GOLD: medalRating.MEDAL_GOLD,
  OFFER_MEDAL_SILVER: medalRating.MEDAL_SILVER,
  OFFER_MEDAL_BRONZE: medalRating.MEDAL_BRONZE,
};
