import React from "react";
import {useTranslation} from "react-i18next";
//local
import SellerSideProposalDescription from "./SellerSideProposalDescription";
import LoadDocsColumn from "components/LoadDocsColumn";
import HistoryInfo from "components/HistoryInfo";
import {historyOfferAmountTableColumns} from "constants/table.consts";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {IconEdit, IconDocumentPen} from "components/icons";
import {dateFormat, dateTimeFormat} from "helpers/datetime";

const SellerSideProposalView = ({offer, request, language, currentUser, onEdit}) => {
  const {t} = useTranslation();

  return (
    <React.Fragment>
      <div className="main-info">
        <div className="main-info_block">
          <div className="main-info_block_header">{t("OFFER_DATE")}</div>
          <div className="main-info_block_date">{dateTimeFormat(offer?.createdAt)}</div>
        </div>
        <div className="main-info_block">
          <div className="main-info_block_header">{t("VALID_TILL")}</div>
          <div className="main-info_block_date">{dateFormat(offer?.validUntil)}</div>
        </div>
        <div className="pt-8">
          <ButtonTransparent
            addClass="view-header__status-row__button button-edit"
            type="link"
            icon={<IconEdit />}
            onClick={onEdit}
            tooltip="EDIT_OFFER_SELLER_SIDE_TOOLTIP"
          />
        </div>
      </div>
      <div className="main-info">
        <div className="main-info_block">
          <div className="main-info_block_header">{t("OFFER_FROM")}</div>
          <div className="main-info_block_name">{offer?.Proposal.Organization.name}</div>
        </div>
        <div className="main-info_block">
          <div className="main-info_block_header">{t("TOTAL_OFFER")}</div>
          <div className="main-info_block_value df-row-baseline">
            <span className="seller-value green">
              {offer?.price.toLocaleString(language ?? "sv")}
            </span>
            <span className="currency green">{` ${request.CurrencyCode}`}</span>
            <HistoryInfo
              data={offer?.OfferPriceHistoryEvents}
              columns={historyOfferAmountTableColumns}
              showAmount
            />
          </div>
        </div>
        <div className="ml-8"></div>
      </div>
      <SellerSideProposalDescription offer={offer} />

      <LoadDocsColumn
        title="DOCUMENTS"
        titleIcon={<IconDocumentPen />}
        addClass="mt-8"
        needZipButton
        titleLeft
        fullWidth
        needAuthorInfo
        currentUser={currentUser}
        docs={offer?.OfferFiles ?? []}
      />
    </React.Fragment>
  );
};

export default SellerSideProposalView;
