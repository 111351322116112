import React, {memo} from "react";
import {useTranslation} from "react-i18next";
// locale
import ButtonDelete from "components/buttons/ButtonDelete";
import SimpleField from "components/SimpleField";

const InviteUsersFields = memo(({index, remove, loading}) => {
  const {t} = useTranslation();
  const fieldName = `invites.${index}.email`;

  return (
    <div className="invite-user_form__item">
      <SimpleField
        placeholder={t("WHO_DO_WE_SEND_THIS_INVITATION")}
        name={fieldName}
        errorName={fieldName}
        className="input"
        disabled={loading}
      />
      {index !== 0 && <ButtonDelete onClick={() => remove(index)} disabled={loading} />}
    </div>
  );
});

export default InviteUsersFields;
