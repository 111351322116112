import React, {useEffect, useState} from "react";
import {inject, observer} from "mobx-react";
import {useTranslation} from "react-i18next";
// locale
import EmptyData from "components/EmptyData";
import {Preloader} from "components/Preloader";
import InviteUsersForm from "./InviteUsersForm";
import InviteUserList from "./InviteUserList";

const InviteUsers = inject("store")(
  observer(({store: {auth, organization}}) => {
    const {user} = auth;
    const [fetching, setFetching] = useState(true);
    const {t} = useTranslation();
    const extraPersonPrice =
      user?.ActiveOrganization?.SubscribeUnit?.extraPersonsPrice?.replace(".00", "");

    useEffect(() => {
      organization.getInviteUsers().finally(() => setFetching(false));
    }, [organization]);

    return (
      <div className="invite-user__wrapper">
        <h3 className="invite-user__title">{t("SEND_INVITE_TO_USERS_TITLE")}</h3>
        <InviteUsersForm
          organizationStore={organization}
          extraPersonPrice={extraPersonPrice ?? "-"}
        />

        <h3 className="invite-user__title">{t("SENT_INVITES")}</h3>
        {fetching ? (
          <div className="invite-user__table __wrapper __preloader">
            <Preloader addClass="preloader500" />
          </div>
        ) : (
          <div className="invite-user__table __wrapper">
            {organization?.inviteUsers?.length ? (
              <InviteUserList organizationStore={organization} />
            ) : (
              <EmptyData />
            )}
          </div>
        )}
      </div>
    );
  })
);

export default InviteUsers;
