import React, {useEffect} from "react";
import {inject, observer} from "mobx-react";
import {useTranslation} from "react-i18next";
// local
import PresentationCardWrapper from "../../PresentationCardWrapper";
import ContactsEdit from "./ContactForms/ContactsEdit";
import ContactCard from "components/ContactCard";
import EmptyData from "components/EmptyData";
import {LockValue} from "components/LockValue";
import {IconUsers} from "components/icons";

const ContactPersons = inject("store")(
  observer(({store: {organization}, edit, setEdit}) => {
    const {t} = useTranslation();
    const {organizationDetail, organizationMembersSelectList} = organization;
    const closeEdit = () => setEdit({...edit, contacts: false});

    const contacts = !!organizationDetail?.Contacts?.length
      ? organizationDetail?.Contacts
      : [];

    const mapData = () =>
      contacts.map((contact, i) => <ContactCard key={contact.id} contact={contact} />);

    useEffect(() => {
      organization.getOrganizationMembers();
    }, [organization]);

    return edit.contacts ? (
      <ContactsEdit
        members={organizationMembersSelectList}
        contacts={contacts}
        closeEdit={closeEdit}
      />
    ) : (
      <PresentationCardWrapper
        title={t("CONTACT_PERSONS")}
        icon={<IconUsers />}
        edit={edit}
        setEdit={setEdit}
        editName="contacts"
        isActive={organizationDetail?.isActive}
        addClass="__contact-person"
      >
        <LockValue
          canPermissions={["READ_OTHER_CONTACT_PERSONS"]}
          noHideOurInfo
          fullSubcribeButton
        >
          <div className="contact-person_wrapper">
            {!!contacts.length ? mapData() : <EmptyData />}
          </div>
        </LockValue>
      </PresentationCardWrapper>
    );
  })
);

export default ContactPersons;
