import React from "react";
import {useTranslation} from "react-i18next";
import {inject, observer} from "mobx-react";
// local
import OverviewManualCardView from "../../OverviewCard/OverviewManualCardView";
import PresentationCardWrapper from "../../PresentationCardWrapper";
import {dateFormat} from "helpers/datetime";
import InsuranceEdit from "./InsuranceEdit";
import EmptyData from "components/EmptyData";
import {IconFirstAid} from "components/icons";
import {getFiles} from "helpers/helper";
import {LockValue} from "components/LockValue";

const Insurance = inject("store")(
  observer(({store: {organization}, edit, setEdit}) => {
    const {t} = useTranslation();
    const closeEdit = () => setEdit({...edit, insurance: false});

    if (edit.insurance) return <InsuranceEdit closeEdit={closeEdit} />;

    const mapData = () =>
      !!organization.organizationDetail?.Insurances?.length &&
      organization.organizationDetail?.Insurances.map((item) => (
        <OverviewManualCardView
          key={item.id}
          firstParameter={item.insuranceName}
          secondParameter={item.insuranceCompany}
          validDate={dateFormat(item.valid)}
          meets={item.meets}
          downloadFiles={getFiles(item)}
        />
      ));

    return (
      <PresentationCardWrapper
        icon={<IconFirstAid />}
        edit={edit}
        setEdit={setEdit}
        editName="insurance"
        title={t("INSURANCE")}
        addClass="__insurance-area"
        isActive={organization.organizationDetail?.isActive}
      >
        <LockValue
          canPermissions={["READ_OTHER_INSURANCE"]}
          noHideOurInfo
          fullSubcribeButton
          needMiniSibscribeButton
        >
          {mapData() || <EmptyData />}
        </LockValue>
      </PresentationCardWrapper>
    );
  })
);

export default Insurance;
