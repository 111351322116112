import React, {useEffect, useState} from "react";
import {inject, observer} from "mobx-react";
import {useTranslation} from "react-i18next";
//locale
import {IconDocumentPen} from "components/icons";
import OurOrderTopInfo from "../components/componentsOurOfferTab/OurOrderTopInfo";
import OurOrderMessage from "../components/componentsOurOfferTab/OurOrderMessage";
import EditOurOffers from "../components/componentsOurOfferTab/EditOurOffers/EditOurOffers";
import LoadDocsColumn from "components/LoadDocsColumn";
import SmartFormAnswers from "pages/OurRequests/components/SmartForm/SmartFormAnswers";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {IconEdit} from "components/icons";
import {Preloader} from "components/Preloader";

const OurOfferSection = inject("store")(
  observer(({store: {auth, clientStore, seller}}) => {
    const {t} = useTranslation();
    const {language} = clientStore;
    const {user} = auth;
    const {request, offerFiles} = seller;
    const [edit, setEdit] = useState(false);
    const create = !request.Offer?.id;
    const openEdit = () => setEdit(true);

    useEffect(() => {
      if (
        Object.keys(request).length &&
        !!request.Offer?.id &&
        request.Offer?.id !== seller.currentOfferId
      ) {
        seller.getOffer(seller.currentOfferId);
      }
    }, [seller, seller.currentOfferId, request]);

    if (edit || create)
      return <EditOurOffers create={create} edit={edit} setEdit={setEdit} />;

    return (
      <>
        {!seller.offerLoading ? (
          <div className="request-to-us_single __wrapper">
            <div className="df-row">
              <OurOrderTopInfo request={request} language={language} />
              <div className="current-our-offer_edit-btn __wrapper">
                <ButtonTransparent
                  icon={<IconEdit />}
                  name={"EDIT_OFFER"}
                  noBorder
                  addClass="pa-0"
                  onClick={openEdit}
                />
              </div>
            </div>
            <div className="current-our-offer_sub-info __wrapper">
              <OurOrderMessage request={request} />
              <div className="current-our-offer_documents load-docs_wrapper __wrapper">
                <div className="load-docs_column-load">
                  <LoadDocsColumn
                    title={t("TENDER_DOCUMENT")}
                    titleIcon={<IconDocumentPen />}
                    button={"ADD_DOCUMENT"}
                    docs={offerFiles}
                    fullWidth
                    needAuthorInfo
                    currentUser={user}
                  />
                </div>
              </div>
            </div>
            <SmartFormAnswers
              isNew={false}
              isEdit={false}
              isSellerSide
              titleHide={!request?.Request?.smartFormQuestions?.length}
              questions={request?.Request?.smartFormQuestions}
              answers={request?.Offer?.smartFormAnswers ?? []}
            />
          </div>
        ) : (
          <Preloader />
        )}
      </>
    );
  })
);

export default OurOfferSection;
