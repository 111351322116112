import React, {Fragment, useState, useEffect} from "react";
import {useLocation} from "react-router-dom";
import {inject, observer} from "mobx-react";
import Animate from "rc-animate";
import {useTranslation} from "react-i18next";
//local
import MessagePanel from "components/MessagePanel";
import {
  IconChevronDown,
  IconChevronUp,
  IconHourglass,
  IconBullHorn,
} from "components/icons";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import {showSuccess} from "helpers/notifications.helper";
import {CustomBadge} from "components/CustomBadge";
import {Preloader} from "components/Preloader";
import {color} from "constants/color.consts";
import cn from "classnames";

const MessageCard = inject("store")(
  observer(({store: {buyer, auth}, user, isRegistered = true, expand, onExpandClick}) => {
    const {user: authUser} = auth;
    const {currentRequest} = buyer;
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [innerLoading, setInnerLoading] = useState(false);
    const {t} = useTranslation();
    const location = useLocation();

    const loadMessages = (noLoading) => {
      !!user && !noLoading && setLoading(true);
      !!user &&
        buyer
          .getRfpOrganizationMessages({
            rfpId: currentRequest.id,
            organizationId: user.id,
          })
          .then((data) => {
            setMessages(data.messages);
            user.unreadMessageCount &&
              buyer.updateCurrentRequestInfo({
                recipients: {
                  ...currentRequest.recipients,
                  registered: currentRequest.recipients.registered.map((item) =>
                    item.id === user.id ? {...item, unreadMessageCount: 0} : {...item}
                  ),
                },
              });
          })
          .finally(() => setLoading(false));
    };

    const onHeaderClick = (isFromNotifications) => {
      const isNeedClick = !!user?.id && !expand;
      isNeedClick && loadMessages();
      !isFromNotifications && onExpandClick(user?.id);
    };

    const clickSend = async (message, file) => {
      setInnerLoading(true);
      const data = file
        ? await buyer.messageUploadFile({
            rfpId: currentRequest.id,
            file,
          })
        : null;
      buyer
        .createRfpMessage({
          rfpId: currentRequest.id,
          organizationId: user?.id ?? null,
          fileId: data?.id ?? data,
          message,
        })
        .then(() => {
          user?.id && loadMessages(true);
          showSuccess(t("SUCCESSFULLY_SENT_MESSAGE"));
        })
        .finally(() => {
          buyer.getCurrentRequestInfo(currentRequest.id);
          setInnerLoading(false);
        });
    };

    // Opening a message panel with a specific proposal
    useEffect(
      () => {
        user?.id &&
          (location?.state?.messageProposalId === user.id ||
            location?.search?.replace("?messageProposalId=", "") === user.id) &&
          onHeaderClick(true);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [location, user]
    );

    return (
      <div className={cn("message-card__wrapper", {clickable: isRegistered})}>
        {isRegistered ? (
          <Fragment>
            <div className="message-card__header" onClick={() => onHeaderClick(false)}>
              {user ? (
                <React.Fragment>
                  <div
                    className={cn("message-card__header-title", {
                      bold: !!user.unreadMessageCount,
                    })}
                  >
                    {user.name}
                  </div>
                  <CustomBadge
                    count={user.totalMessageCount}
                    color="midGray"
                    addClass="mr-2"
                  />
                  <CustomBadge count={user.unreadMessageCount} addClass="mr-2" />
                </React.Fragment>
              ) : (
                <div className="df-row-center mr-4">
                  <IconBullHorn addClass="mr-2" />
                  {t("MESSAGES_TO_ALL_LABEL")}
                </div>
              )}
              <ButtonTransparent
                addClass="message-card__header-button"
                icon={
                  (user ? user.id === expand : !!expand) ? (
                    <IconChevronUp />
                  ) : (
                    <IconChevronDown />
                  )
                }
                noBorder
                tiny
              />
            </div>
            <Animate
              transitionName="fade"
              transitionAppear
              transitionLeave={false}
              className="message-card__dropdown__wrapper"
            >
              {(user ? user.id === expand : !!expand) && (
                <div className="message-card__messages-wrapper">
                  <MessagePanel
                    mode="create"
                    loading={innerLoading}
                    message={{
                      User: {
                        firstName: authUser.firstName,
                        lastName: authUser.lastName,
                      },
                    }}
                    placeholder={`${t("NEW_MESSAGE_PLACEHOLDER")} ${
                      user?.name ?? t("MESSAGES_TO_ALL_PLACEHOLDER")
                    }`}
                    addClass="mb-4"
                    sendMessage={clickSend}
                    isMyMessage
                  />
                  {loading && <Preloader addClass="preloader100" />}
                  {!loading && (
                    <div className="message-card__messages-list">
                      {!!messages?.length &&
                        messages.map((message, index) => (
                          <MessagePanel
                            key={message.id}
                            message={message}
                            addClass={index < messages.length - 1 ? "mb-4" : "mb-0"}
                            isMyMessage={authUser.id === message.UserId}
                          />
                        ))}
                    </div>
                  )}
                </div>
              )}
            </Animate>
          </Fragment>
        ) : (
          <div className="message-card__header-unregistered">
            <IconHourglass color={color.midGray} addClass="mr-3" />
            <div className="message-card__header-unregistered-title">{user.email}</div>
          </div>
        )}
      </div>
    );
  })
);

export default MessageCard;
