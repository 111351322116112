import * as yup from "yup";
import {emailRequired} from "yup/defaultValudations";

export const schema = yup.object().shape({
  invites: yup.array().of(
    yup.object().shape({
      email: emailRequired(),
    })
  ),
});

export const initialValues = {email: ""};
