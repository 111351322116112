import React from "react";
import {Dropdown} from "antd";
import {useTranslation} from "react-i18next";
// local
import {IconEllipsis, IconCog} from "components/icons";
import {ButtonTransparent} from "./buttons/ButtonTransparent";
import {Preloader} from "components/Preloader";
import cn from "classnames";

export const DropdownEllipsis = ({
  list,
  loading,
  buttonColor,
  tightMenu,
  thinMenu,
  colorMenu,
  needCogIcon,
  addClass,
}) => {
  const {t} = useTranslation();

  const items = list
    .filter((item) => !item.hide)
    .map((row) => ({
      key: row.label,
      label: (
        <div
          className={cn(
            "dropdown-ellipsis__row",
            {"thin-row": !!thinMenu},
            {"tight-row": !!tightMenu}
          )}
          onClick={row.onClick}
        >
          {row.icon}
          <span style={colorMenu && {color: colorMenu}}>{t(row.label)}</span>
        </div>
      ),
    }));

  return (
    <Dropdown
      menu={{items}}
      trigger={["click"]}
      overlayClassName="dropdown-ellipsis__list"
    >
      {loading ? (
        <Preloader size="small" addClass="ml-5" />
      ) : (
        <ButtonTransparent
          addClass={cn({[addClass]: !!addClass})}
          icon={
            needCogIcon ? (
              <IconCog color={buttonColor} />
            ) : (
              <IconEllipsis color={buttonColor} />
            )
          }
          tiny
          noBorder
        />
      )}
    </Dropdown>
  );
};
