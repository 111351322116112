import React, {useEffect, useState, useMemo, useCallback} from "react";
import {inject, observer} from "mobx-react";
import cn from "classnames";
import {debounce} from "lodash";
import {v4 as uuidv4} from "uuid";
// local
import {useTranslation} from "react-i18next";
import {ConfirmModal} from "components/ConfirmModal";
import DocumentRevisionTabs from "components/requestCard/DocumentRevisionTabs";
import {TitleIcon} from "components/TitleIcon";
import LoadDocsColumn from "components/LoadDocsColumn";
import SelectDocColumn from "components/selects/SelectDocColumn";
import AddLinkModal from "components/modals/AddLinkModal/AddLinkModal";
import {fileType, docType} from "constants/file.consts";
import {saveDocument} from "helpers/helper";
import {IconDocumentPen, IconRules, IconLink, IconBriefcase} from "components/icons";
import AddDaluxLinkModal from "components/modals/AddDaluxLinkModal";

const RequestDocuments = inject("store")(
  observer(
    ({
      store: {buyer},
      revisions,
      editable,
      needZipButton,
      disableZipButton,
      columnTitles,
      allRevisionsView,
      showRevisionNameInColumn,
      customLoadDocument,
      twoRowView,
      showTabs,
      intoContract,
      standardAgreement,
      publicView,
    }) => {
      const {isAddLinkModal, isAddDaluxLinkModal} = buyer;
      const {t} = useTranslation();
      const [loading, setLoading] = useState(false);
      const [selectItems, setSelectItems] = useState([]);
      const [loadColumnTitle, setLoadColumnTitle] = useState("");

      const isShowTooltip = useMemo(
        () => showTabs && editable && revisions?.length < 2,
        [showTabs, revisions?.length, editable]
      );

      // REVISIONS
      const [revisionId, setRevisionId] = useState(revisions?.[0]?.id);
      const revision = useMemo(
        () => revisions?.find((item) => item.id === revisionId),
        [revisions, revisionId]
      );
      const documents = useMemo(
        () => ({
          contracts: revision?.contracts ?? [],
          documents: revision?.documents ?? [],
          plans: revision?.plans ?? [],
          links: revision?.links ?? [],
        }),
        [revision]
      );
      const collectDocuments = useCallback(
        (key) =>
          revisions?.reduce(
            (docs, item) =>
              docs.concat(
                item[key].length
                  ? showRevisionNameInColumn
                    ? [{id: item.id, revisionName: item.name}, ...item[key]]
                    : item[key]
                  : []
              ),
            []
          ),
        [revisions, showRevisionNameInColumn]
      );
      const allDocuments = useMemo(
        () => ({
          contracts: collectDocuments("contracts"),
          documents: collectDocuments("documents"),
          plans: collectDocuments("plans"),
          links: collectDocuments("links"),
        }),
        [collectDocuments]
      );
      const data = useMemo(
        () => ({
          contracts: allRevisionsView
            ? allDocuments.contracts ?? []
            : documents.contracts ?? [],
          documents: allRevisionsView
            ? allDocuments.documents ?? []
            : documents.documents ?? [],
          plans: allRevisionsView ? allDocuments.plans ?? [] : documents.plans ?? [],
          links: allRevisionsView ? allDocuments.links ?? [] : documents.links ?? [],
        }),
        [allDocuments, documents, allRevisionsView]
      );

      const onChangeSelect = debounce(
        (value) => buyer.updateCurrentRequestInfo({standardAgreement: value}),
        2000
      );

      const contractType = (
        <SelectDocColumn
          label={"STANDARD_AGREEMENT"}
          className="mb-0 mt-6"
          elements={selectItems}
          selected={standardAgreement}
          editable={editable}
          onChangeSelect={onChangeSelect}
        />
      );

      const handleGetContractRevision = () => {
        const contractRevision = revisions?.find(
          (item) => item.name === "RFP_FOR_CONTRACT_REVISION_NAME"
        );
        const newContractRevisionId = uuidv4();
        setRevisionId(contractRevision?.id ?? newContractRevisionId);
        const newContractRevision = {
          id: newContractRevisionId,
          name: "RFP_FOR_CONTRACT_REVISION_NAME",
          contracts: [],
          documents: [],
          plans: [],
          links: [],
        };
        return contractRevision ?? newContractRevision;
      };

      const clickAdd = (e, title) => {
        const data = new FormData();
        setLoadColumnTitle(title);
        data.append("file", e.file.originFileObj);
        data.append("type", fileType[title]);
        setLoading(true);
        const currentRevision = intoContract ? handleGetContractRevision() : revision;
        buyer
          .getUID(data)
          .then((data) => {
            setLoading(false);
            buyer.updateCurrentRequestInfo({
              documents: [
                ...revisions.filter((item) => item.id !== currentRevision.id),
                {
                  ...currentRevision,
                  [docType[title]]: [...currentRevision[docType[title]], data],
                },
              ],
            });
          })
          .finally(() => setLoading(false));
      };

      const clickAddLink = () => {
        if (intoContract) {
          const currentRevision = handleGetContractRevision();
          buyer.updateCurrentRequestInfo({
            documents: [
              ...revisions.filter((item) => item.id !== currentRevision.id),
              currentRevision,
            ],
          });
        }
        buyer.setAddLinkModalStatus(true);
      };

      const clickRemove = (id, title) =>
        ConfirmModal({
          title: t(id ? "WANT_DELETE_FILE" : "WANT_DELETE_ALL_FILES"),
          text: t(id ? "CANT_DELETE_FILE" : "CANT_DELETE_ALL_FILES"),
          type: "warning",
          onOk: () => removeDocument(id, title),
        });

      const removeDocument = (id, title) =>
        buyer.updateCurrentRequestInfo({
          documents: revisions.map((item) =>
            (
              allRevisionsView
                ? item[docType[title]]?.find((file) => file.id === id)
                : item.id === revisionId
            )
              ? {
                  ...item,
                  [docType[title]]: id // id === null, if we click remove all button
                    ? item[docType[title]].filter((val) => val.id !== id)
                    : [],
                }
              : item
          ),
        });

      const loadDaluxFile = async (url) => {
        const urlData = url.split("/");
        let fileName = urlData[1];
        const data = await buyer.getDaluxProjectFileAreaFile(
          urlData[2],
          urlData[3],
          urlData[4]
        );
        if (!data) return;
        saveDocument(data, "application/octet-stream", fileName, t("FILE_DOWNLOADED"));
      };

      const removeLink = (url) =>
        buyer.updateCurrentRequestInfo({
          documents: revisions.map((item) =>
            item.id === revisionId
              ? {...item, links: item.links.filter((item) => item.url !== url)}
              : item
          ),
        });

      useEffect(() => {
        buyer
          .getAgreements()
          .then((response) =>
            setSelectItems([...response.data, "NO_STANDARD_AGREEMENT"])
          );
      }, [buyer]);

      return (
        <React.Fragment>
          {!allRevisionsView && (
            <TitleIcon
              title={t("RFP_REQUEST_TAB_DOCUMENTS_SECTION_TITLE")}
              addClass="mt-8"
              icon={<IconBriefcase />}
              tooltip={
                isShowTooltip
                  ? "RFP_REQUEST_TAB_DOCUMENTS_SECTION_TITLE_TOOLTIP"
                  : undefined
              }
              prefixTooltipContent={
                showTabs ? (
                  <DocumentRevisionTabs
                    revisions={revisions}
                    revisionId={revisionId}
                    isEditing={editable}
                    onChangeTab={setRevisionId}
                  />
                ) : null
              }
            />
          )}
          <div className={cn("load-docs_wrapper")}>
            <LoadDocsColumn
              title={columnTitles.contracts}
              docPlaceholder="PLACEHOLDER_BUYER_CONTRACT_COLUMN"
              titleIcon={<IconDocumentPen />}
              button="ADD"
              editable={editable}
              docs={data.contracts}
              addFile={clickAdd}
              removeFile={clickRemove}
              canRemoveAllFiles
              needZipButton={needZipButton}
              disableZipButton={disableZipButton}
              loading={loading}
              allRevisionsView={allRevisionsView}
              loadColumnTitle={loadColumnTitle}
              bottomChildren={!publicView && contractType}
              customLoadDocument={customLoadDocument?.contracts}
            />
            <LoadDocsColumn
              title={columnTitles.documents}
              docPlaceholder="PLACEHOLDER_BUYER_DOCUMENT_COLUMN"
              titleIcon={<IconDocumentPen />}
              button="ADD"
              docs={data.documents}
              editable={editable}
              addFile={clickAdd}
              removeFile={clickRemove}
              canRemoveAllFiles
              needZipButton={needZipButton}
              loading={loading}
              allRevisionsView={allRevisionsView}
              loadColumnTitle={loadColumnTitle}
              customLoadDocument={customLoadDocument?.documents}
            />
            <LoadDocsColumn
              title={columnTitles.plans}
              docPlaceholder="PLACEHOLDER_BUYER_DOCUMENT_COLUMN"
              titleIcon={<IconRules />}
              button="ADD"
              docs={data.plans}
              editable={editable}
              addFile={clickAdd}
              removeFile={clickRemove}
              canRemoveAllFiles
              needZipButton={needZipButton}
              loading={loading}
              allRevisionsView={allRevisionsView}
              loadColumnTitle={loadColumnTitle}
              customLoadDocument={customLoadDocument?.plans}
            />
            <LoadDocsColumn
              title={columnTitles.links}
              docPlaceholder="PLACEHOLDER_BUYER_LINKS_COLUMN"
              titleIcon={<IconLink />}
              button="ADD"
              wide
              docs={data.links}
              editable={editable}
              removeFile={removeLink}
              canRemoveAllFiles
              loading={loading}
              allRevisionsView={allRevisionsView}
              customAdd={clickAddLink}
              linksView
              customLoadDocument={loadDaluxFile}
              loadColumnTitle={loadColumnTitle}
              isOpenModal={isAddLinkModal}
              modal={<AddLinkModal revisionId={revisionId} />}
            />
          </div>
          {isAddDaluxLinkModal && <AddDaluxLinkModal revisionId={revisionId} />}
        </React.Fragment>
      );
    }
  )
);

export default RequestDocuments;
