import React, {useEffect, useState} from "react";
import {inject, observer} from "mobx-react";
import {useTranslation} from "react-i18next";
//local
import Notification from "./components/Notification";
import {Member} from "./components/Member";
import {MembersSummary} from "./components/MembersSummary";
import {ModalOwnerInvite} from "./components/ModalOwnerInvite";
import {MemberSettingsModal} from "./components/MemberSettingsModal";
import {showSuccess} from "helpers/notifications.helper";
import {VideoInstructionLink} from "components/VideoInstructionLink";

export const Members = inject("store")(
  observer(({store: {organization, auth}}) => {
    const {organizationMembers, memberInfo, organizationDetail, subscriptionRoles} =
      organization;
    const {t} = useTranslation();
    const [isModalOwnerId, setIsModalOwnerId] = useState(null);
    const [isMemberSettingsModal, setMemberSettingsModal] = useState(null);
    const {activeOrganizationId, user, clientRole, isStaff} = auth;
    const members = Object.values(organizationMembers).flat();
    const extraPersonPrice =
      user?.ActiveOrganization?.SubscribeUnit?.extraPersonsPrice?.replace(".00", "");

    const handleAcceptJoinRequest = () => {
      setIsModalOwnerId(null);
      organization.joinRequestAccept(isModalOwnerId).then(() => {
        setIsModalOwnerId(null);
        organization.requestsToJoinOrganization();
        organization.getOrganizationMembers();
      });
    };

    const handleDenyJoinRequest = (id) =>
      organization.joinRequestDeny(id).then(() => {
        organization.requestsToJoinOrganization();
      });

    const onUpdateMember = (userId, value) =>
      organization.updateMember(userId, value).then(() => showSuccess());

    const removeMember = (userId) =>
      organization.removeMember(userId).then(() => {
        setMemberSettingsModal(null);
        organization.getOrganizationMembers();
      });

    useEffect(() => {
      organization.getOrganizationMembers();
      organization.getAvailableSubscriptionRoles();
      organization.requestsToJoinOrganization();
    }, [organization, activeOrganizationId]);

    return (
      <div className="members">
        <div
          className={`member_notification__wrapper ${
            memberInfo?.length > 4 ? "__box" : ""
          }`}
        >
          {isStaff &&
            !!memberInfo?.length &&
            memberInfo.map(({User, joinMessage}) => (
              <Notification
                key={User.id}
                user={User}
                joinMessage={joinMessage}
                acceptJoinRequest={() => setIsModalOwnerId(User.id)}
                denyJoinRequest={() => handleDenyJoinRequest(User.id)}
                organizationName={user?.ActiveOrganization?.name}
                extraPersonPrice={extraPersonPrice ?? "-"}
              />
            ))}
        </div>
        <div className="header-wrapper">
          {t("PEOPLE_IN")}
          {user?.ActiveOrganization?.name}
          <VideoInstructionLink
            label={`VIDEO_LINK_SETUP_MEMBERS_LABEL`}
            link={`VIDEO_LINK_SETUP_MEMBERS_LINK`}
          />
        </div>

        {!!members.length &&
          members.map((member, index) => (
            <Member
              key={member.UserId}
              index={index}
              userInfo={member.User}
              role={member.role}
              subscriptionRole={
                subscriptionRoles.find((item) => item.id === member.SubscribeRoleId)?.name
              }
              availableIn={member.availableIn}
              ownMember={member.UserId === user?.id}
              isChecked={member.isRfpEmailNotifications}
              userId={member.UserId}
              organizationId={activeOrganizationId}
              onChange={() => setMemberSettingsModal(member)}
              isSettingButton={isStaff || member.UserId === user?.id}
            />
          ))}

        <MembersSummary members={members} />

        {isMemberSettingsModal && (
          <MemberSettingsModal
            title={`${t("ORGANIZATION_TAB_MEMBERS_SETTINGS_MODAL_TITLE")} ${
              isMemberSettingsModal.User.firstName
            } ${isMemberSettingsModal.User.lastName}`}
            member={isMemberSettingsModal}
            isLastMember={
              members?.filter((item) => item.isRfpEmailNotifications).length < 2
            }
            availableInList={
              organizationDetail?.SearchPreference?.availableIn ??
              user?.ActiveOrganization?.SearchPreference?.availableIn ??
              []
            }
            subscriptionRoles={subscriptionRoles}
            clientRole={clientRole}
            onRemove={removeMember}
            onOk={onUpdateMember}
            onClose={() => setMemberSettingsModal(null)}
          />
        )}

        {isModalOwnerId && (
          <ModalOwnerInvite
            onClose={() => setIsModalOwnerId(null)}
            users={["user"]}
            onAccept={handleAcceptJoinRequest}
            extraPersonPrice={extraPersonPrice}
          />
        )}
      </div>
    );
  })
);
