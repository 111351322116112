import React from "react";
import {inject, observer} from "mobx-react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Select} from "antd";
//local
import {orderingNameValues} from "constants/select.consts";
import {changeSelectValue, changeFilterParams} from "helpers/helper";

const {Option} = Select;

const OurOffersTableHead = inject("store")(
  observer(({store: {seller}}) => {
    const {offerFilterParams} = seller;
    const {t} = useTranslation();
    const navigate = useNavigate();

    const onChangeOrder = (value) => {
      const params = changeFilterParams(value);
      updateUrlParams(`?${new URLSearchParams(params)}`);
      seller.setOfferFilterParams(params);
      seller.getOffers();
    };

    const updateUrlParams = (search) => navigate({search});

    const options = (values) =>
      values.map(({value, label}, i) => (
        <Option key={i} value={value}>
          {t(label)}
        </Option>
      ));

    return (
      <div className="requests-card_head">
        <div className="requests-card_head__name __wrapper">
          <div className="requests-card_head__name __select">
            <Select
              onChange={onChangeOrder}
              value={changeSelectValue(orderingNameValues, offerFilterParams)}
            >
              {options(orderingNameValues)}
            </Select>
          </div>
        </div>
        <div className="requests-card_head__column __wrapper">{t("LOCATION")}</div>
        <div className="requests-card_head__column __wrapper">{t("TITLE")}</div>
        <div className="requests-card_head__column __wrapper">
          {t("CLOSES_FOR_OFFERS")}
        </div>
        <div className="requests-card_head__column __wrapper">{t("BUYER_RESPONSE")}</div>
        <div className="requests-card_head__empty __wrapper"></div>
      </div>
    );
  })
);

export default OurOffersTableHead;
