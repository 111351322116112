import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {inject, observer} from "mobx-react";
// local
import OverviewCardView from "../../OverviewCard/OverviewCardView";
import PresentationCardWrapper from "../../PresentationCardWrapper";
import CollectiveUnionAgreementsEdit from "./CollectiveUnionAgreementsEdit/CollectiveUnionAgreementsEdit";
import EmptyData from "components/EmptyData";
import {getFiles} from "helpers/helper";
import {externalApiTypes} from "constants/user.consts";
import {IconGroupWork} from "components/icons";
import {LockValue} from "components/LockValue";

const overviewCollectiveColumnTitles = [
  "OVERVIEW_TAB_COLUMN_UNION_PARTY",
  "OVERVIEW_TAB_COLUMN_EMPLOYER_PARTY",
  "OVERVIEW_TAB_COLUMN_AGREEMENT",
  "OVERVIEW_TAB_COLUMN_SOURCE",
];

const externalApiOrder = [
  externalApiTypes.KOLLEKTIVAVTALSKOLLEN,
  externalApiTypes.BYGGINSTSEKTORN_COLLECTIVE,
  externalApiTypes.BYGGINSTSEKTORN_SUBSTITUTE,
];

const CollectiveUnionAgreements = inject("store")(
  observer(({store: {organization}, edit, setEdit}) => {
    const {t} = useTranslation();
    const {organizationDetail} = organization;
    const closeEdit = () => setEdit({...edit, unionAgreements: false});
    const organizationUnionAgreements = organizationDetail?.UnionAgreements;
    const inactiveMemberByggforetagen =
      organizationDetail?.Company?.inactiveMemberByggforetagen;

    const companyUnionAgreements = useMemo(
      () => organizationDetail?.Company?.CompanyUnionAgreements ?? [],
      [organizationDetail?.Company]
    );

    const sortedCompanyUnionAgreements = useMemo(
      () =>
        [...companyUnionAgreements].sort((a, b) =>
          externalApiOrder.findIndex((item) => item === a.sourceApi) >
          externalApiOrder.findIndex((item) => item === b.sourceApi)
            ? 1
            : -1
        ),
      [companyUnionAgreements]
    );

    if (edit.unionAgreements)
      return <CollectiveUnionAgreementsEdit closeEdit={closeEdit} />;

    const mapManualData = () =>
      !!organizationUnionAgreements?.length &&
      organizationUnionAgreements.map((item) => (
        <OverviewCardView
          key={item.id}
          columnTitles={overviewCollectiveColumnTitles}
          columnData={{
            [item.localAgreement ? 1 : 0]: t("COMPANY"),
            [item.localAgreement ? 0 : 1]: t(item.organizationName),
            3: t("COMPANY"),
          }}
          downloadFiles={getFiles(item)}
          wideColumnIndex={3}
        />
      ));

    const mapData = () =>
      !!sortedCompanyUnionAgreements?.length &&
      sortedCompanyUnionAgreements.map((item) => (
        <OverviewCardView
          key={item.id}
          isSourceShield
          columnTitles={overviewCollectiveColumnTitles}
          crossed={
            inactiveMemberByggforetagen &&
            item.sourceApi !== externalApiTypes.KOLLEKTIVAVTALSKOLLEN &&
            item.source === "Byggföretagen"
          }
          columnData={{
            0: item.unionParty,
            1: item.employerParty ? item.employerParty : t("COMPANY"),
            2: item.rawName ?? item.avtal ?? t("COMPANY"),
            3: item.source ?? item.unionParty,
          }}
          wideColumnIndex={3}
        />
      ));

    return (
      <PresentationCardWrapper
        icon={<IconGroupWork />}
        title={t("COLLECTIVE_UNION_AGREEMENTS")}
        edit={edit}
        setEdit={setEdit}
        editName="unionAgreements"
        addClass="__collective-union-agreements-area"
        isActive={organizationDetail?.isActive}
      >
        <LockValue
          canPermissions={["READ_OTHER_COLLECTIVE_UNION_AGREEMENTS"]}
          noHideOurInfo
          fullSubcribeButton
        >
          <>
            <OverviewCardView
              columnTitles={overviewCollectiveColumnTitles}
              isTitle
              wideColumnIndex={3}
            />
            {mapManualData()}
            {mapData()}
            {!mapData() && !mapManualData() && <EmptyData />}
          </>
        </LockValue>
      </PresentationCardWrapper>
    );
  })
);

export default CollectiveUnionAgreements;
