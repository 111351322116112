import React, {useState, useEffect} from "react";
import {inject, observer} from "mobx-react";
import {useLocation} from "react-router-dom";
import {Form, Formik} from "formik";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import debounce from "lodash.debounce";
//local
// import {VideoInstructionLink} from "components/VideoInstructionLink";
import {toProjectId} from "constants/routes.const";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import CustomRadioSwitch from "components/CustomRadioSwitch";
import {OffersSwitchOffModal} from "../RequestEdit/OffersSwitchOffModal";
import {ChooseCategoriesModal} from "../RequestEdit/ChooseCategoriesModal";
import {RemindersModal} from "../RequestEdit/RemindersModal";
import {CustomTooltip} from "components/CustomTooltip";
import EditDatePecker from "./EditDatePecker";
import EditSelect from "components/selects/EditSelect";
import SimpleField from "components/SimpleField";
import FormikField from "components/FormikField";
import {InfoTooltip} from "components/InfoTooltip";
import MiniSelect from "components/selects/MiniSelect";
import {
  IconChevronLeft,
  IconNotificationCircle,
  IconCheck,
  IconEyeCrossed,
  IconLock,
  IconBell,
  IconCog,
  IconPlus,
  IconCurrency,
} from "components/icons";
import {ProjectLabel} from "components/ProjectLabel";
import {hasUserPermission} from "helpers/helper";
import {roles} from "constants/auth.const";
import {rfpStatuses} from "constants/buyer.consts";
import {getFullName, isObject} from "helpers/helper";
import {dateTimeFormat} from "helpers/datetime";
import {color} from "constants/color.consts";
import {requestSchema} from "./formAttrs";
import cn from "classnames";

const RequestHeadEdit = inject("store")(
  observer(({store: {buyer, clientStore, auth, nsi}}) => {
    const {currentRequest, projects, isEdit, rfpRecipientsFilterParams} = buyer;
    const {language} = clientStore;
    const {user, clientRole} = auth;
    const {t} = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [currencies, setCurrencies] = useState(null);
    const [isChooseCategoriesModal, setChooseCategoriesModal] = useState(false);
    const [isHideOffersSwitchOffModal, setHideOffersSwitchOffModal] = useState(false);
    const [isHardDateCloseOffersSwitchOffModal, setHardDateCloseOffersSwitchOffModal] =
      useState(false);
    const [isRemindersModal, setRemindersModal] = useState(false);
    const requestLocation = currentRequest.location ?? currentRequest.Project?.location;
    const initialLocation = {
      lat: +(currentRequest.locationLat ?? currentRequest.Project?.locationLat),
      lng: +(currentRequest.locationLng ?? currentRequest.Project?.locationLng),
    };
    const membersList =
      location?.state?.project?.id && !!projects
        ? location?.state?.project?.Members
        : currentRequest?.Project?.Members?.map((val) => ({
            value: val.id,
            label: getFullName(val.firstName, val.lastName),
          })).filter((val) => val.label) ?? [];

    const noLocation = Object.values(initialLocation).some((item) => !item);
    // tooltip history row
    const historyRow = (user, date, status) =>
      `${t(`CREATE_RFP_HIDE_OFFERS_TOOLTIP_${status ? "ON" : "OFF"}`)} ${getFullName(
        user.firstName,
        user.lastName
      )} | ${dateTimeFormat(date)}`;

    // hard/soft close date tooltip text
    const hardDateCloseOfferTooltipTextCreate = t(
      "CREATE_RFP_HARD_DATE_CLOSE_OFFERS_TOOLTIP_MAIN"
    );
    const hardDateCloseOfferSwitchHistory = currentRequest?.HardDateCloseOffersHistory
      ?.length
      ? currentRequest.HardDateCloseOffersHistory.map(
          (item, i) =>
            `${i === 0 ? "\n" : ""}${historyRow(
              item.User,
              item.updatedAt,
              item.hardDateCloseOffers
            )}\n`
        ).join("")
      : "";
    const hardDateCloseOfferTooltipTextEdit = `${t(
      "CREATE_RFP_HARD_DATE_CLOSE_OFFERS_TOOLTIP_MAIN"
    )}\n${hardDateCloseOfferSwitchHistory}`;

    // hide offers section
    const isOffersHideInfo =
      isEdit &&
      currentRequest.status === rfpStatuses.PUBLISHED &&
      !hasUserPermission([roles.ADMIN, roles.OWNER], clientRole);
    // hide offers tooltip text
    const hideOfferTooltipTextCreate = t("CREATE_RFP_HIDE_OFFERS_TOOLTIP_MAIN");
    const hideOfferSwitchHistory = currentRequest?.OffersHideHistory?.length
      ? currentRequest.OffersHideHistory.map(
          (item, i) =>
            `${i === 0 ? "\n" : ""}${historyRow(
              item.User,
              item.updatedAt,
              item.offersHidden
            )}\n`
        ).join("")
      : "";
    const hideOfferTooltipTextEdit = `${t(
      "CREATE_RFP_HIDE_OFFERS_TOOLTIP_MAIN"
    )}\n${hideOfferSwitchHistory}`;

    const CategoriesButton = ({categories}) => (
      <ButtonTransparent
        name={
          categories.length > 1
            ? `${t(categories[0])} +${categories.length - 1}`
            : categories.length
            ? categories[0]
            : "CREATE_RFP_CATEGORIES_BUTTON"
        }
        onClick={() => setChooseCategoriesModal(true)}
        icon={!categories.length && <IconPlus />}
        nonTransparent={categories.length}
        rounded
        small
      />
    );

    const changeRequest = debounce((data) => buyer.updateCurrentRequestInfo(data), 1000);
    const changeRequestNow = (data) => buyer.updateCurrentRequestInfo(data);

    const handleChange = (e) => {
      buyer.setDraftSavedStatus(false);
      if (e?.target && e.target.name === "name") changeRequest({name: e.target.value});

      if (e?.target && e.target.name === "location")
        changeRequestNow({location: e.target.value});
      if (e?.coordinates)
        changeRequestNow({
          location: e.coordinates.location,
          locationLat: e.coordinates.lat,
          locationLng: e.coordinates.lng,
        });
      if (e?._d && +new Date(e) > Date.now())
        changeRequestNow({
          closedAt: new Date(new Date(e._d).setHours(23, 59, 59, 0)).toISOString(),
        });
      if (isObject(e) && "offersHidden" in e) changeRequestNow(e);
      if (isObject(e) && "hardDateCloseOffers" in e) changeRequestNow(e);
      if (isObject(e) && "CurrencyCode" in e) changeRequestNow(e);
      if (isObject(e) && "reminders" in e) changeRequestNow(e);
      if (isObject(e) && "reminderDays" in e) changeRequestNow(e);
      if (isObject(e) && "categories" in e) {
        buyer.setRfpRecipientsFilterParams({
          ...rfpRecipientsFilterParams,
          ...e.categories,
        });
        changeRequestNow(e.categories);
      }
      if (typeof e === "string") changeRequestNow({ContactPersonId: e});
      if (!e) changeRequestNow({closedAt: null});
    };

    useEffect(() => {
      location?.state?.project?.id &&
        !!projects &&
        buyer.updateCurrentRequestInfo(
          {
            name: location?.state?.rfpName,
            ProjectId: location?.state?.project.id,
            Project: location?.state?.project,
            projectItemId: location?.state?.projectItemId,
            ContactPersonId: location?.state?.ContactPersonId,
            CurrencyCode: user.ActiveOrganization?.defaultCurrencyCode,
          },
          true
        );
    }, [buyer, location, projects, user]);

    useEffect(() => {
      buyer.validateCurrentRequest(
        !!currentRequest.name &&
          !!currentRequest.closedAt &&
          []
            .concat(
              currentRequest.entrepreneurWithin,
              currentRequest.entrepreneurOffers,
              currentRequest.materialSupplierType,
              currentRequest.consultingServices
            )
            .filter((item) => !!item).length
      );
    }, [currentRequest, buyer]);

    useEffect(() => {
      isEdit &&
        buyer.setNewPublishedRFPRecipients({
          notRemoveRegistered: [...currentRequest.recipients.registered],
          notRemoveNotRegistered: [...currentRequest.recipients.notRegistered],
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEdit]);

    useEffect(() => {
      nsi.getCurrencies().then(setCurrencies);
    }, [nsi]);

    return (
      <div className="request-edit-block">
        {/*
        <VideoInstructionLink
          label="VIDEO_LINK_RFP_EDIT_LABEL"
          link="VIDEO_LINK_RFP_EDIT_LINK"
          alignRight
          addClass="mb-4"
        />
        */}
        {(location?.state?.project?.id ? currentRequest.Project?.id : true) && (
          <Formik
            enableReinitialize
            initialValues={currentRequest}
            validationSchema={requestSchema}
          >
            {({values, setFieldValue}) => (
              <Form onChange={handleChange}>
                <div>
                  <div className="main-fields_wrapper">
                    <div className="main-fields_wrapper__row-info">
                      <div className="main-fields_wrapper__project">
                        {currentRequest.Project?.id && (
                          <ButtonTransparent
                            icon={<IconChevronLeft color={color.purple} />}
                            noBorder
                            small
                            addClass="view-header__back-button"
                            onClick={() =>
                              navigate(toProjectId(currentRequest.Project.id))
                            }
                          />
                        )}
                        <ProjectLabel
                          name={currentRequest.Project?.name}
                          code={currentRequest.Project?.reference}
                        />
                      </div>
                      <div className="main-fields_wrapper__input name">
                        <SimpleField
                          name="name"
                          autoFocus
                          placeholder={t("NAME_REQUEST_PLACEHOLDER")}
                          className="input"
                          highlightIfEmpty={!values.name}
                        />
                        <div
                          className={cn("categories-button-wrapper", {
                            "bg-yellow": ![]
                              .concat(
                                values.entrepreneurOffers,
                                values.entrepreneurWithin,
                                values.materialSupplierType,
                                values.consultingServices
                              )
                              .filter((item) => !!item).length,
                          })}
                        >
                          <CategoriesButton
                            categories={[]
                              .concat(
                                values.entrepreneurOffers,
                                values.entrepreneurWithin,
                                values.materialSupplierType,
                                values.consultingServices
                              )
                              .filter((item) => !!item)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="main-fields_wrapper__row-info wrappable">
                      <div className="main-fields_wrapper__input location mr-4">
                        <FormikField
                          name="location"
                          placeholder={t("ADDRESS_PLACEHOLDER")}
                          errorName="location"
                          value={requestLocation}
                          withGoogleMapsAutocomplete
                          withGoogleMapsModal
                          language={language}
                          initialLocation={noLocation ? null : {...initialLocation}}
                          onChangeCoordinates={({lat, lng, location}) => {
                            setFieldValue("location", location);
                            setFieldValue("locationLat", lat);
                            setFieldValue("locationLng", lng);
                            handleChange({coordinates: {lat, lng, location}});
                          }}
                          className="input"
                        />
                      </div>
                      <div className="close-date">
                        <EditDatePecker
                          onChange={handleChange}
                          name="closedAt"
                          placeholder="CLOSES_FOR_OFFERS"
                          highlightIfEmpty={!values.closedAt}
                        />
                        <InfoTooltip tooltip="RFP_CLOSE_DATE_INFO_TOOLTIP" />
                      </div>

                      <div className="toggles">
                        <div className="main-fields_wrapper__input hide-offers ml-0">
                          <IconCurrency addClass="mr-1" />
                          <MiniSelect
                            list={currencies ?? []}
                            defaultValue={
                              currentRequest.CurrencyCode ??
                              user.ActiveOrganization?.defaultCurrencyCode
                            }
                            fullWidthDropdownLabel
                            onChange={(value) => {
                              setFieldValue("CurrencyCode", value);
                              handleChange({CurrencyCode: value});
                            }}
                            informationText="RFP_CURRENCY_TOOLTIP"
                          />
                        </div>
                        <div className="main-fields_wrapper__input hide-offers">
                          <CustomRadioSwitch
                            name="reminders"
                            checked={values?.reminders}
                            uppercase
                            small
                            addWrapperClass="mr-1"
                            isActive={(checked) => {
                              setFieldValue("reminders", checked);
                              handleChange({reminders: checked});
                            }}
                          />
                          <span className="hide-offers-label">
                            {t("CREATE_RFP_REMEMBER_LABEL")}
                          </span>
                          <CustomTooltip text="CREATE_RFP_REMEMBER_LABEL">
                            <div className="hide-offers-label-icon mr-1">
                              <IconBell />
                            </div>
                          </CustomTooltip>
                          <ButtonTransparent
                            icon={<IconCog />}
                            noBorder
                            tiny
                            addClass="pl-0 pr-0"
                            onClick={() => setRemindersModal(true)}
                          />
                        </div>
                        <div className="main-fields_wrapper__input hide-offers">
                          <CustomRadioSwitch
                            name="hardDateCloseOffers"
                            checked={values?.hardDateCloseOffers}
                            uppercase
                            small
                            addWrapperClass="mr-1"
                            isActive={(checked) => {
                              if (checked) {
                                setFieldValue("hardDateCloseOffers", checked);
                                handleChange({hardDateCloseOffers: checked});
                              } else setHardDateCloseOffersSwitchOffModal(true);
                            }}
                          />
                          <span className="hide-offers-label">
                            {t("CREATE_RFP_HARD_DATE_CLOSE_OFFERS_LABEL")}
                          </span>
                          <CustomTooltip text="CREATE_RFP_HARD_DATE_CLOSE_OFFERS_LABEL">
                            <div className="hide-offers-label-icon mr-1">
                              <IconLock />
                            </div>
                          </CustomTooltip>

                          <CustomTooltip
                            text={
                              isEdit
                                ? hardDateCloseOfferTooltipTextEdit
                                : hardDateCloseOfferTooltipTextCreate
                            }
                            centerText={!isEdit}
                            addClass="request-head-edit-hide-offers-icon-tooltip"
                            noTranslate
                          >
                            <div style={{height: "21px"}}>
                              <IconNotificationCircle
                                color={color.black}
                                className="mr-0"
                              />
                            </div>
                          </CustomTooltip>
                        </div>

                        {!(isOffersHideInfo && !values?.offersHidden) && (
                          <div className="main-fields_wrapper__input hide-offers">
                            {isOffersHideInfo ? (
                              <IconCheck addClass="mr-1" color={color.green} />
                            ) : (
                              <CustomRadioSwitch
                                name="offersHidden"
                                checked={values?.offersHidden}
                                uppercase
                                small
                                addWrapperClass="mr-1"
                                isActive={(checked) => {
                                  if (checked) {
                                    setFieldValue("offersHidden", checked);
                                    handleChange({offersHidden: checked});
                                  } else setHideOffersSwitchOffModal(true);
                                }}
                              />
                            )}
                            <span className="hide-offers-label">
                              {t("CREATE_RFP_HIDE_OFFERS_LABEL")}
                            </span>
                            <CustomTooltip text="CREATE_RFP_HIDE_OFFERS_LABEL">
                              <div className="hide-offers-label-icon mr-1">
                                <IconEyeCrossed />
                              </div>
                            </CustomTooltip>
                            <CustomTooltip
                              text={
                                isEdit
                                  ? hideOfferTooltipTextEdit
                                  : hideOfferTooltipTextCreate
                              }
                              centerText={!isEdit}
                              addClass="request-head-edit-hide-offers-icon-tooltip"
                              noTranslate
                            >
                              <div style={{height: "21px"}}>
                                <IconNotificationCircle
                                  color={color.black}
                                  className="mr-0"
                                />
                              </div>
                            </CustomTooltip>
                          </div>
                        )}
                      </div>
                      <EditSelect
                        addClass="contact-person"
                        name="ContactPersonId"
                        list={membersList}
                        onChange={handleChange}
                        defaultValue={membersList[0]?.value}
                        initials
                        ownValue={user.id}
                        label={`${t("OUR_CONTACT")}:`}
                      />
                    </div>
                  </div>
                </div>
                {isHardDateCloseOffersSwitchOffModal && (
                  <OffersSwitchOffModal
                    title="MODAL_HARD_DATE_SWITCH_TITLE"
                    description="MODAL_HARD_DATE_CLOSE_OFFERS_SWITCH_DESCRIPTION"
                    onOk={() => {
                      setFieldValue("hardDateCloseOffers", false);
                      handleChange({hardDateCloseOffers: false});
                    }}
                    onClose={() => setHardDateCloseOffersSwitchOffModal(false)}
                  />
                )}
                {isHideOffersSwitchOffModal && (
                  <OffersSwitchOffModal
                    title="MODAL_HIDE_OFFERS_SWITCH_TITLE"
                    description="MODAL_HIDE_OFFERS_SWITCH_DESCRIPTION"
                    needAttention
                    onOk={() => {
                      setFieldValue("offersHidden", false);
                      handleChange({offersHidden: false});
                    }}
                    onClose={() => setHideOffersSwitchOffModal(false)}
                  />
                )}
                {isChooseCategoriesModal && (
                  <ChooseCategoriesModal
                    initialCategories={{
                      entrepreneurOffers: values.entrepreneurOffers ?? [],
                      entrepreneurWithin: values.entrepreneurWithin ?? [],
                      materialSupplierType: values.materialSupplierType ?? [],
                      consultingServices: values.consultingServices ?? [],
                    }}
                    onOk={(data) => {
                      Object.entries(data).forEach((category) =>
                        setFieldValue(category[0], category[1])
                      );
                      handleChange({categories: data});
                    }}
                    onClose={() => setChooseCategoriesModal(false)}
                  />
                )}
                {isRemindersModal && (
                  <RemindersModal
                    reminderDays={values.reminderDays ?? []}
                    closedAt={values?.closedAt}
                    onOk={(days) => {
                      setFieldValue("reminderDays", days);
                      handleChange({reminderDays: days});
                    }}
                    onClose={() => setRemindersModal(false)}
                  />
                )}
              </Form>
            )}
          </Formik>
        )}
      </div>
    );
  })
);

export default RequestHeadEdit;
