import React, {memo} from "react";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
// local
import {LockValue} from "components/LockValue";

export const Tab = memo(
  ({
    onClick,
    name,
    addClass,
    img,
    icon,
    hash,
    count,
    rightContent,
    leftContent,
    permission,
    disabledClick,
    disabled,
  }) => {
    const {t} = useTranslation();

    const handleStopClick = (e) => e.preventDefault();

    return (
      <React.Fragment>
        {leftContent}
        <LockValue
          canPermissions={permission && [permission]}
          needLabel
          needRightIcon={true}
          needShowModalToSubscribe
          addClassIcon="lock-value-tab-icon"
          addClassWrapper="lock-value-tab-wrapper"
        >
          <button
            className={`tab ${addClass}`}
            onClick={disabledClick ? handleStopClick : onClick}
            disabled={disabled}
          >
            <Link to={hash}>
              {img ? <img src={img} alt={hash} /> : null}
              {icon}
              <p className="text">{t(name)}</p>
              {!!count ? <span>{count}</span> : null}
            </Link>
          </button>
        </LockValue>
        {rightContent}
      </React.Fragment>
    );
  }
);
