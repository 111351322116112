import React from "react";
import {useTranslation} from "react-i18next";
//local
import PresentationCardWrapper from "../../PresentationCardWrapper";
import {IconUsers} from "components/icons";
import {LockValue} from "components/LockValue";

const Employees = ({organization}) => {
  const {t} = useTranslation();

  return (
    <PresentationCardWrapper icon={<IconUsers />} title={t("OVERVIEW_TAB_EMPLOYEES")}>
      <LockValue
        canPermissions={["READ_OTHER_EMPLOYEES"]}
        noHideOurInfo
        fullSubcribeButton
        needMiniSibscribeButton
      >
        <span className="mr-3 fz-12 discrete-text">
          {t("ANNUAL_REPORT_NUMBER_EMPLOYEES")}
        </span>
        <span>{organization?.Company?.basic?.nrEmployees ?? "-"}</span>
        <div className="mt-2 fz-12 mid-gray ws-br-spaces">
          {t("ANNUAL_REPORT_NUMBER_EMPLOYEES_DESCRIPTION")}
        </div>
      </LockValue>
    </PresentationCardWrapper>
  );
};

export default Employees;
