import React from "react";
import {inject, observer} from "mobx-react";
// local
import {Tab} from "./Tab";
import {hasPermission} from "helpers/helper";
import cn from "classnames";

export const Tabs = inject("store")(
  observer(
    ({
      store: {auth},
      tabs,
      counts,
      redCounts = [],
      active,
      setActive,
      permissions = [],
      addClass,
      edit,
      setEdit,
      disabledTabs,
      leftContent,
      rightContent,
    }) => {
      const {user} = auth;
      const openTab = (id) => setActive(id);
      const content = tabs.find((item) => item.id === active)?.content;

      return (
        <>
          <div className={addClass}>
            {tabs.map(
              (item, i) =>
                item.visible && (
                  <Tab
                    hash={item.hash}
                    key={item.id}
                    name={item.title}
                    count={counts ? counts[i] : null}
                    img={item.img}
                    icon={item.icon}
                    addClass={cn(`${item.id === active ? "tab__active" : "tab__bold"}`, {
                      [item.class]: !!item.class,
                      "red-count": !!redCounts[i],
                    })}
                    permission={permissions[i]}
                    disabledClick={
                      permissions[i]
                        ? !hasPermission([permissions[i]], user.permissions)
                        : false
                    }
                    onClick={() => openTab(item.id)}
                    leftContent={leftContent && leftContent[item.title.replace(" ", "")]}
                    rightContent={
                      rightContent && rightContent[item.title.replace(" ", "")]
                    }
                    disabled={item.disabled || (disabledTabs && disabledTabs[item.title])}
                  />
                )
            )}
          </div>

          {content && (
            <section className="tabs-content">
              {React.createElement(content, {
                edit,
                setEdit,
              })}
            </section>
          )}
        </>
      );
    }
  )
);
