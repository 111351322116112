import React from "react";
import {useTranslation} from "react-i18next";
import {inject, observer} from "mobx-react";
//local
import OverviewManualCardView from "../../OverviewCard/OverviewManualCardView";
import PresentationCardWrapper from "../../PresentationCardWrapper";
import {dateFormat} from "helpers/datetime";
import LicensesAndCertificatesEdit from "./LicensesAndCertificatesEdit";
import EmptyData from "components/EmptyData";
import {getFiles} from "helpers/helper";
import {IconLicense} from "components/icons";
import {LockValue} from "components/LockValue";

const LicensesAndCertificates = inject("store")(
  observer(({store: {organization}, edit, setEdit}) => {
    const {t} = useTranslation();
    const {organizationDetail} = organization;
    const closeEdit = () => setEdit({...edit, licenses: false});

    if (edit.licenses) return <LicensesAndCertificatesEdit closeEdit={closeEdit} />;

    const mapData = () =>
      !!organizationDetail?.Licenses?.length &&
      organizationDetail.Licenses.map((item) => (
        <OverviewManualCardView
          key={item.id}
          firstParameter={item.name}
          validDate={dateFormat(item.validDate)}
          downloadFiles={getFiles(item)}
        />
      ));

    return (
      <PresentationCardWrapper
        icon={<IconLicense />}
        title={t("LICENSES_&_CERTIFICATES")}
        edit={edit}
        setEdit={setEdit}
        editName="licenses"
        isActive={organizationDetail?.isActive}
      >
        <LockValue
          canPermissions={["READ_OTHER_LICENSES_CERTIFICATES"]}
          noHideOurInfo
          fullSubcribeButton
          needMiniSibscribeButton
        >
          <> {mapData() || <EmptyData />} </>
        </LockValue>
      </PresentationCardWrapper>
    );
  })
);

export default LicensesAndCertificates;
