import React, {memo} from "react";

export const IconChat = memo(({color, addClass}) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill={color || "none"}
      className={addClass}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path d="M19.501 18C19.501 18 19.501 18 19.5 18C18.159 18 16.766 17.144 16.253 16.794C15.69 16.93 15.102 17 14.5 17C13.063 17 11.705 16.604 10.678 15.884C9.596 15.127 9 14.102 9 13C9 11.898 9.596 10.873 10.678 10.116C11.706 9.397 13.063 9 14.5 9C15.937 9 17.295 9.396 18.322 10.116C19.404 10.873 20 11.898 20 13C20 13.964 19.539 14.877 18.694 15.597C18.776 15.828 19.033 16.324 19.839 17.132C19.938 17.223 20 17.354 20 17.5C20 17.776 19.776 18 19.5 18H19.501ZM16.353 15.752C16.461 15.752 16.567 15.787 16.655 15.853C16.665 15.86 17.33 16.357 18.158 16.695C17.614 15.9 17.628 15.447 17.677 15.257C17.706 15.148 17.77 15.052 17.86 14.984C18.595 14.432 18.999 13.728 18.999 13C18.999 12.233 18.555 11.5 17.748 10.935C16.887 10.332 15.733 10 14.499 10C13.265 10 12.111 10.332 11.25 10.935C10.443 11.5 9.999 12.233 9.999 13C9.999 13.767 10.443 14.5 11.25 15.065C12.111 15.668 13.265 16 14.499 16C15.094 16 15.672 15.923 16.217 15.77C16.261 15.758 16.307 15.752 16.352 15.752H16.353Z" />
        <path d="M0.500176 19C0.275176 19 0.0781761 18.85 0.0181761 18.633C-0.0418239 18.416 0.0501761 18.186 0.243176 18.071C1.93418 17.057 2.63518 15.582 2.88418 14.892C1.04618 13.485 0.000176137 11.538 0.000176137 9.50002C0.000176137 8.47102 0.258176 7.47402 0.768176 6.53602C1.25418 5.64202 1.94818 4.84102 2.82918 4.15502C4.61618 2.76502 6.98518 1.99902 9.50018 1.99902C11.7782 1.99902 13.9812 2.64302 15.7022 3.81302C17.4372 4.99202 18.5732 6.62402 18.9012 8.40802C18.9512 8.68002 18.7712 8.94002 18.5002 8.99002C18.2292 9.04002 17.9682 8.86002 17.9182 8.58902C17.6392 7.07002 16.6522 5.66802 15.1402 4.64002C13.5832 3.58202 11.5802 2.99902 9.50018 2.99902C4.81318 2.99902 1.00018 5.91502 1.00018 9.49902C1.00018 11.314 2.00518 13.061 3.75618 14.291C3.92818 14.412 4.00618 14.627 3.95218 14.83C3.83518 15.266 3.43718 16.463 2.37218 17.618C3.67418 17.162 5.07618 16.371 6.11118 15.659C6.23418 15.574 6.38818 15.549 6.53218 15.59C7.48018 15.861 8.47918 15.999 9.50018 15.999C9.77618 15.999 10.0002 16.223 10.0002 16.499C10.0002 16.775 9.77618 16.999 9.50018 16.999C8.46718 16.999 7.45318 16.87 6.48418 16.614C6.05518 16.9 5.25318 17.407 4.29518 17.884C2.80718 18.624 1.53118 18.999 0.501176 18.999L0.500176 19Z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="20" height="20" />
        </clipPath>
      </defs>
    </svg>
  );
});
