import React, {useEffect, useState, useCallback, useMemo} from "react";
import {inject, observer} from "mobx-react";
import {useParams, useNavigate, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {debounce, isEqual} from "lodash";
//local
import {PROJECTS} from "constants/routes.const";
import {Layout} from "components/UI/layout";
import {ButtonTransparent} from "components/buttons/ButtonTransparent";
import ProjectHeadView from "./components/ProjectView/ProjectHeadView";
import ProcurementsHead from "./components/ProcurementsHead";
import ProcurementItem from "./components/ProjectView/ProcurementItem";
import ProjectCostRow from "./components/ProjectView/ProjectCostRow";
import ProjectsSelect from "components/selects/ProjectsSelect";
import {Tabs} from "components/Tabs";
import {procurementTabs} from "constants/tabs.const";
import {ProjectModal} from "components/modals/ProjectModal";
import {ProcurementModal} from "components/modals/ProcurementModal";
import {RFPCreateModal} from "components/modals/RFPCreateModal";
import {formattedNumber} from "helpers/number.formats";
import {Preloader} from "components/Preloader";
import {InfoTooltip} from "components/InfoTooltip";
import {showSuccess, showWarning} from "helpers/notifications.helper";
import {defaultRequestParamsSearch} from "constants/user.consts";
import {color} from "constants/color.consts";
import {ProjectStartGuide} from "./components/ProjectStartGuide";
import Confetti from "components/Confetti";
import {
  IconPlus,
  IconInbox,
  IconPlane,
  IconSheet,
  IconContract,
  IconCart,
  IconTruck,
  IconChevronDown,
  IconCopy,
} from "components/icons/index";
import {getFullName} from "helpers/helper";
import cn from "classnames";

const datesSteps = [
  {
    key: "timelineCreateRftDate",
    icon: <IconSheet color={color.white} />,
    tooltip: "PROCUREMENTS_DATES_TAB_HEAD_ICON_CREATE_RFP",
    titleColor: "blue",
  },
  {
    key: "timelinePublishRftDate",
    icon: <IconPlane color={color.white} />,
    tooltip: "PROCUREMENTS_DATES_TAB_HEAD_ICON_PUBLISH_RFP",
    titleColor: "green",
  },
  {
    key: "timelineReceiveTendersDate",
    icon: <IconInbox color={color.white} />,
    tooltip: "PROCUREMENTS_DATES_TAB_HEAD_ICON_RECEIVE_TENDERS",
    titleColor: "green",
  },
  {
    key: "timelineContractReadyDate",
    icon: <IconContract color={color.white} />,
    tooltip: "PROCUREMENTS_DATES_TAB_HEAD_ICON_CONTRACT_READY",
    titleColor: "black",
  },
  {
    key: "timelinePlaceOrderDate",
    icon: <IconCart color={color.black} />,
    tooltip: "PROCUREMENTS_DATES_TAB_HEAD_ICON_PLACE_ORDER",
    titleColor: "white",
  },
  {
    key: "timelineDeliveryDate",
    icon: <IconTruck color={color.black} />,
    tooltip: "PROCUREMENTS_DATES_TAB_HEAD_ICON_DELIVERY",
    titleColor: "white",
  },
];

const ProjectPage = inject("store")(
  observer(({store: {auth, buyer, organization}}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const {user} = auth;
    const {
      activeProcurementsTab,
      loading,
      expandedProcurementsList,
      currentProcurementId,
      projects,
    } = buyer;
    const {projectId} = useParams();
    const [project, setProject] = useState(null);
    const [createRFPProjects, setCreateRFPProjects] = useState(null);
    const [loadingProcurements, setLoadingProcurements] = useState(true);
    const [loadingProcurementId, setLoadingProcurementId] = useState(null);
    const [isInitialFilter, setIsInitialFilter] = useState(false);
    const [procurements, setProcurements] = useState(null);
    const [selectedProcurementIds, setSelectedProcurementIds] = useState([]);

    const [statuses, setStatuses] = useState(null);
    const [isProjectModal, setIsProjectModal] = useState(null);
    const [isProcurementModal, setIsProcurementModal] = useState(null);
    const [isRFPModal, setIsRFPModal] = useState(null);
    const [expandLoadingId, setExpandLoadingId] = useState(null);
    const [procurementsAssignees, setProcurementsAssignees] = useState(null);

    // use an effect if this project was just created
    const [showNewProjectEffect] = useState(location.state?.isNewProject);

    const [requestParams, setRequestParams] = useState(defaultRequestParamsSearch);
    const isInitialFilterParams = useMemo(
      () => isEqual({...requestParams}, {...defaultRequestParamsSearch}),
      [requestParams]
    );

    const updateUrlParams = useCallback((search) => navigate({search}), [navigate]);

    const getCurrentProcurements = useCallback(
      async (id, params) => {
        try {
          const data = await buyer.getProcurements(id, requestParams);
          setProcurements(data);

          buyer.setProjects(null);
          buyer.setExpandedProcurementsList(
            expandedProcurementsList.filter((item) =>
              data.map((procurement) => procurement.id).includes(item.id)
            )
          );
          return data;
        } finally {
          setLoadingProcurementId(null);
          setLoadingProcurements(false);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [buyer, requestParams]
    );

    const getCurrentProject = useCallback(
      async (id, notLoading, params) => {
        try {
          const projectData = await buyer.getProject(id, notLoading);
          setProject(projectData);
          !notLoading &&
            !statuses?.length &&
            buyer.getProcurementStatuses().then(setStatuses);
          const procurementsData = await getCurrentProcurements(id, params);
          // getting projects (for select) only for empty project
          !procurementsData.length && buyer.getProjects(true, "all");
        } catch (error) {
          navigate(PROJECTS);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [buyer, projectId, navigate]
    );

    const onChangeProjectBudget = debounce((costBudget) => {
      buyer
        .updateProject({id: project.id, costBudget})
        .then(({data}) => setProject(data));
    }, 1000);

    const deleteProcurement = (procurementId) =>
      buyer.deleteProcurement(projectId, procurementId).then(() => {
        showSuccess(t("DELETING_PROCUREMENT"));
        setProcurements((state) =>
          state.filter((procurement) => procurement.id !== procurementId)
        );
      });

    const showRFPCreateModal = (value) =>
      buyer.getCreateRFPProjects().then((projects) => {
        setCreateRFPProjects(projects.filter((project) => !project.isArchived));
        setIsRFPModal(value);
      });

    const onChangeFilters = (name, value) => {
      const params = {...requestParams, [name]: value};
      if (!params?.status?.length) delete params["status"];
      if (!params?.userId?.length) delete params["userId"];
      if (!params.noRft) delete params["noRft"];
      setRequestParams(params);
      // Separate conversion for correct search string generation
      const urlParams = Object.entries(params).reduce(
        (obj, item) => ({
          ...obj,
          [`${item[0]}${Array.isArray(item[1]) ? "[]" : ""}`]: item[1],
        }),
        {}
      );
      updateUrlParams(`?${new URLSearchParams(urlParams)}`);
    };

    const handleExpandProcurement = (id) => {
      if (expandedProcurementsList.map((item) => item.id).includes(id))
        buyer.setExpandedProcurementsList(
          expandedProcurementsList.filter((item) => item.id !== id)
        );
      else {
        setExpandLoadingId(id);
        buyer
          .getProcurement(projectId, id)
          .then((procurement) =>
            buyer.setExpandedProcurementsList([...expandedProcurementsList, procurement])
          )
          .finally(() => setExpandLoadingId(null));
      }
    };

    const handleChangeInterestedProposalStatus = (procurementId, proposalId, values) => {
      setExpandLoadingId(procurementId);
      buyer
        .updateProposal(proposalId, values)
        .then(() =>
          buyer
            .getProcurement(projectId, procurementId)
            .then((procurement) =>
              buyer.setExpandedProcurementsList([
                ...expandedProcurementsList.filter((item) => item.id !== procurementId),
                procurement,
              ])
            )
        )
        .finally(() => setExpandLoadingId(null));
    };

    const handleChangeProject = (id) =>
      buyer.getProcurements(id).then((data) =>
        data.length
          ? buyer
              .createProcurementsList(
                projectId,
                data.map(({name}) => ({name}))
              )
              .then(setProcurements)
          : showWarning(t("PROCUREMENT_BUTTON_COPY_ALL_EMPTY_PROJECT"))
      );

    const handleBulkProcurementUpdate = (values) =>
      buyer.procurementsBulkUpdate(projectId, {
        procurementIds: selectedProcurementIds,
        ...values,
      });

    useEffect(() => {
      organization.getFavorites();
      return () => buyer.setActiveProcurementsTab(0);
    }, [organization, buyer]);

    // getting procurements after sorting
    useEffect(() => {
      !loadingProcurements && getCurrentProcurements(projectId, requestParams);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getCurrentProcurements, projectId, requestParams]);

    // initial getting procurements after getting filters
    useEffect(() => {
      if (isInitialFilter) getCurrentProject(projectId, false, requestParams);
      else {
        const initialParams = Object.fromEntries(new URLSearchParams(location.search));
        const params = {...requestParams, ...initialParams};
        updateUrlParams(`?${new URLSearchParams(params)}`);

        const initialFilterParams = Object.entries(params).reduce(
          (obj, item) => ({
            ...obj,
            [item[0].replace("[]", "")]: item[0].includes("[]")
              ? item[1].split(",")
              : item[1],
          }),
          {}
        );
        setRequestParams(initialFilterParams);
        setIsInitialFilter(true);
      }
      // eslint-disable-next-line
    }, [isInitialFilter, buyer, getCurrentProject, projectId]);

    // getting organization members if their list is empty
    useEffect(() => {
      buyer.getProcurementsAssignees(projectId).then((members) =>
        setProcurementsAssignees(
          members
            .map((val) => ({
              value: val.id,
              label: getFullName(val.firstName, val.lastName),
            }))
            .filter((val) => val.label)
        )
      );
    }, [buyer, projectId]);

    // scroll to last viewed procurement into procurement list
    useEffect(() => {
      if (!!procurements && currentProcurementId) {
        const lastProcurement = document.getElementById(currentProcurementId);
        !!lastProcurement && lastProcurement.scrollIntoView({behavior: "smooth"});
        !!currentProcurementId && buyer.setCurrentProcurementId(null);
      }
    }, [currentProcurementId, buyer, procurements]);

    return (
      <Layout>
        <div className="project__wrapper">
          {loading && <Preloader addClass="preloader500" />}
          {!loading && project && (
            <>
              <div className="df-row-jsb-center">
                {/* Show an effect if this project was created just now */}
                <Confetti trigger={showNewProjectEffect} />
                <ProjectHeadView project={project} editProject={setIsProjectModal} />
                <Tabs
                  tabs={procurementTabs}
                  active={activeProcurementsTab}
                  permissions={[
                    undefined,
                    "SHOW_PROCUREMENTS_BUDGET_TAB",
                    "SHOW_PROCUREMENTS_TIME_TAB",
                  ]}
                  setActive={(id) => buyer.setActiveProcurementsTab(id)}
                  addClass="project__tabs"
                />
              </div>

              {loadingProcurements && <Preloader addClass="preloader250" />}
              {!loadingProcurements &&
                (!!procurements?.length || !isInitialFilterParams || isInitialFilter) &&
                !!statuses && (
                  <React.Fragment>
                    <ProcurementsHead
                      activeTab={activeProcurementsTab}
                      // expandDisabled={
                      //   expandedProcurementsList.length === procurements.length
                      // }
                      filterParams={requestParams}
                      buyerList={procurementsAssignees}
                      currentUser={user}
                      statuses={statuses.slice(1)}
                      datesSteps={datesSteps}
                      collapseDisabled={!expandedProcurementsList.length}
                      counters={[selectedProcurementIds.length, procurements.length]}
                      visibleCheckboxes={!selectedProcurementIds.length}
                      changeVisibleCheckboxes={() =>
                        setSelectedProcurementIds(procurements.map((item) => item.id))
                      }
                      // clickOnExpand={() =>
                      //   buyer.setExpandedProcurementsList(
                      //     procurements.map((item) => item.id)
                      //   )
                      // }
                      clickOnCollapse={() => buyer.setExpandedProcurementsList([])}
                      clickOnSortFilter={onChangeFilters}
                      clickOnSelect={(checked) =>
                        checked
                          ? setSelectedProcurementIds(procurements.map((item) => item.id))
                          : setSelectedProcurementIds([])
                      }
                      clickOnBulkProcurementsUpdate={handleBulkProcurementUpdate}
                    />
                    <ProjectCostRow
                      project={project}
                      activeTab={activeProcurementsTab}
                      onChangeBudget={onChangeProjectBudget}
                    />
                  </React.Fragment>
                )}
              {!loadingProcurements &&
                !procurements?.length &&
                !isInitialFilterParams && (
                  <div className="__text-center fz-19 mid-gray mt-8">
                    {t("PROCUREMENT_NO_PROCUREMENTS")}
                  </div>
                )}
              {!loadingProcurements &&
                !!procurements?.length &&
                procurements.map((item) => (
                  <ProcurementItem
                    key={item.id}
                    procurement={
                      expandedProcurementsList.find(
                        (expandedItem) => expandedItem.id === item.id
                      ) ?? item
                    }
                    loading={loadingProcurementId === item.id}
                    projectId={project.id}
                    updateProject={() => {
                      setLoadingProcurementId(item.id);
                      getCurrentProcurements(projectId);
                    }}
                    statuses={statuses}
                    currentUserId={user.id}
                    activeTab={activeProcurementsTab}
                    expand={expandedProcurementsList
                      .map((procurement) => procurement.id)
                      .includes(item.id)}
                    checkbox={!!selectedProcurementIds.length}
                    clickExpand={handleExpandProcurement}
                    expandLoading={expandLoadingId === item.id}
                    selected={selectedProcurementIds.includes(item.id)}
                    clickOnSelect={(checked) =>
                      setSelectedProcurementIds((prevState) =>
                        checked
                          ? [...prevState, item.id]
                          : prevState.filter((selectedItem) => selectedItem !== item.id)
                      )
                    }
                    datesSteps={datesSteps.map((date) => ({
                      ...date,
                      value: item[date.key],
                    }))}
                    editProcurement={setIsProcurementModal}
                    deleteProcurement={deleteProcurement}
                    createRFP={showRFPCreateModal}
                    changeInterestedProposalStatus={(proposalId, values) =>
                      handleChangeInterestedProposalStatus(item.id, proposalId, values)
                    }
                  />
                ))}
              {!loadingProcurements && (
                <div
                  className={`project-view_summary-action-row ml-2 mt-${
                    !!procurements?.length ? "4 mra" : "16"
                  }`}
                >
                  <ButtonTransparent
                    name="PROCUREMENT_BUTTON_ADD"
                    icon={<IconPlus />}
                    medium
                    noBorder
                    addClass="pl-0"
                    onClick={() => setIsProcurementModal("new-procurement")}
                  />
                  {!procurements?.length &&
                    isInitialFilterParams &&
                    !activeProcurementsTab &&
                    projects?.length && (
                      <div className="df-row-center">
                        <ButtonTransparent
                          name="PROCUREMENT_BUTTON_COPY_ALL"
                          icon={<IconCopy />}
                          medium
                          noBorder
                          nowrap
                          color="black"
                          addClass="pl-0 ml-6"
                        />
                        <ProjectsSelect
                          list={projects?.filter((project) => project.id !== projectId)}
                          onChange={handleChangeProject}
                          highlightMyProjectsId={user.id}
                          noBorder
                          fullWidth
                          large
                          customIcon={<IconChevronDown color={color.purple} />}
                          noSelectValue={t("PROCUREMENT_BUTTON_COPY_ALL_PROJECT")}
                          addClass="mt-1"
                        />
                        <InfoTooltip
                          tooltip="PROCUREMENT_BUTTON_COPY_ALL_TOOLTIP"
                          centerText
                        />
                      </div>
                    )}
                  {activeProcurementsTab === 1 && (
                    <React.Fragment>
                      <div
                        className={cn("project-view_summary-action-row__summary", {
                          __red: +project.costAvailable < 0,
                        })}
                      >
                        <span className="mr-3">{t("AVAILABLE")}:</span>
                        <span>{formattedNumber(project.costAvailable)}</span>
                      </div>
                      <div className="project-view_summary-action-row__post-summary"></div>
                    </React.Fragment>
                  )}
                </div>
              )}
              {/* Show stepped project guide step 2 when there are 0 procurement items */}
              {!loadingProcurements && isInitialFilterParams && !procurements?.length && (
                <ProjectStartGuide step={2} />
              )}
              {/* Show stepped project guide step 3 when there is exactly 1 procurement item */}
              {!loadingProcurements &&
                isInitialFilterParams &&
                procurements?.length === 1 && <ProjectStartGuide step={3} />}
            </>
          )}
        </div>
        {isProjectModal && (
          <ProjectModal
            projectId={isProjectModal}
            onClose={(needUpdateProject) => {
              setIsProjectModal(null);
              needUpdateProject === true && getCurrentProject(project.id, true);
            }}
          />
        )}
        {isProcurementModal && (
          <ProcurementModal
            projectId={project.id}
            procurementId={isProcurementModal}
            members={project.Members}
            onClose={(needUpdateProject) => {
              setIsProcurementModal(null);
              needUpdateProject === true && getCurrentProcurements(project.id);
            }}
          />
        )}
        {isRFPModal && createRFPProjects && (
          <RFPCreateModal
            projects={createRFPProjects}
            project={project}
            procurement={isRFPModal}
            onClose={() => {
              setCreateRFPProjects(null);
              setIsRFPModal(null);
            }}
          />
        )}
      </Layout>
    );
  })
);

export default ProjectPage;
