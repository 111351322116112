import React from "react";
import {inject, observer} from "mobx-react";
// local
import AnnualReport from "../components/FinancialComponents/AnnualReport/AnnualReport";
import NonPaymentRecords from "../components/FinancialComponents/NonPaymentRecords/NonPaymentRecords";
import UnpaidDebts from "../components/FinancialComponents/UnpaidDebts/UnpaidDebts";
import Signatories from "../components/FinancialComponents/Signatories";
import Management from "../components/FinancialComponents/Management/Management";
import CorporateStructure from "../components/CorporateStructureComponents/CorporateStructure";
import {hasPermission} from "helpers/helper";

const FinancialSection = inject("store")(
  observer(({store: {auth, organization}}) => {
    const {organizationDetail} = organization;
    const {
      user: {permissions},
      activeOrganizationId,
    } = auth;
    const myOrganization = organizationDetail?.id === activeOrganizationId;
    const hasFirstUpdateData = !!organizationDetail.Company.updatedCreditSafeCredit;

    return (
      <div className="finance-section_wrapper">
        <CorporateStructure
          companyData={organizationDetail}
          hasFirstUpdateData={hasFirstUpdateData}
        />
        <AnnualReport
          companyData={organizationDetail}
          hasFirstUpdateData={hasFirstUpdateData}
        />
        <NonPaymentRecords
          companyData={organizationDetail}
          hasFirstUpdateData={hasFirstUpdateData}
        />
        <UnpaidDebts
          companyData={organizationDetail}
          hasFirstUpdateData={hasFirstUpdateData}
        />
        <div
          className={`finance-section_half-block${
            hasPermission(
              ["READ_OTHER_SIGNATORIES", "READ_OTHER_MANAGEMENT"],
              permissions
            ) || myOrganization
              ? " right-dotted"
              : ""
          }`}
        >
          <Signatories
            companyData={organizationDetail}
            hasFirstUpdateData={hasFirstUpdateData}
          />
          <Management
            companyData={organizationDetail}
            hasFirstUpdateData={hasFirstUpdateData}
          />
        </div>
      </div>
    );
  })
);

export default FinancialSection;
